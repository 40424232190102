export const CONSTANT = {

};

export const BUTTONS = {
};

export const STATUS = {
    orderAccepted: 13,
    orderRejected: 14
};

export const ERROR_MESSAGES = {
    REQUIRED: 'Field is required',
    MOQ_VALUE: "MoQ can't be less than 1",
    MOQ_DECIMAL_VALUE: "Moq can't be decimal",
    INVALID_EMAIL: 'Please enter valid email id',
    INVALID_USERNAME: 'Name can contain alphabets and , - & .',
    MAX_LENGTH50: 'Field should be 50 characters long',
    MAX_LENGTH100: 'Field should be 100 characters long',
    MAX_LENGTH250: 'Field should be 250 characters long',
    MAX_LENGTH500: 'Field should be 500 characters long',
    MAX_LENGTH64: 'Field should be 64 characters long',
    MOBILE_NO: 'Please enter valid mobile number',
    PRODUCT_SAVED: 'Product updated Successfully',
    PROFILE_SAVED: 'Profile updated successfully',
    USER_SAVED: 'User updated successfully',
    REASON_SAVED: 'Reject reason saved successfully',
    REASON_DELETED: 'Reject reason deleted successfully',
    IMAGE_SIZE: 'File size allowed: .jpg, .jpeg, .png (<50KB)',
    PRODUCT_ALIAS: 'Product name can be alphanumeric only',
    SELECT_ORDERS: 'Please select orders',
    NO_RECORD_FOUND: 'No matching records found'
};

