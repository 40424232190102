import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ProductService } from '../../services/product.service';
import { AlertService } from '../../utilities/alert';

@Component({
  selector: 'app-admin-product-management',
  templateUrl: './admin-product-management.component.html',
  styleUrls: ['./admin-product-management.component.scss']
})
export class AdminProductManagementComponent implements OnInit {
  dtTrigger: Subject<any> = new Subject();
  isLoading = false;
  productListData: any = [];
  dtOptions: any = {};

  constructor(private productService: ProductService, private alertService: AlertService) {

  }

  ngOnInit(): void {
    this.getProduct();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      lengthMenu: [10, 20, 50],
      processing: true
    };
  }

  getProduct(): void {
    this.isLoading = true;
    this.productService.getProduct().subscribe(
      res => {
        this.isLoading = false;
        this.productListData = res.productMaster;
        this.productListData.forEach(element => {
          if (element.productImage.length) {
            element.productImage[0].product_ImageURL = element.productImage[0].product_ImageURL + '?' + new Date();
          }
        });
        this.dtTrigger.next();
      },
      error => {
        this.isLoading = false;
        this.alertService.error(error.error.title)
      }
    );
  }
}
