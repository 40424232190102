import { Component, HostListener } from '@angular/core';
import { Subject } from 'rxjs';


import { AuthService } from 'src/app/services/auth.service';
import { AlertService } from './utilities/alert/alert.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  userActivity;
  userInactive: Subject<any> = new Subject();

  constructor(private alertService: AlertService, private authService: AuthService) {
    this.setTimeout();
    this.userInactive.subscribe(() => {
      this.authService.logout();
      this.alertService.error('Session timeout due to inactivty.Please login again.')
    });
  }

  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 600000);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
}
