import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthService } from './../../services/auth.service';
import { UserService } from '../../services/user.service';
import { COMMONFORMVALIDATIONRULE } from '../../utilities/formValidation';
import { AlertService } from '../../utilities/alert';
import { ERROR_MESSAGES } from '../../utilities/constants';
@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnInit {
  email: string;
  isLoading = false;
  loginForm: FormGroup;
  errorMsg = ERROR_MESSAGES;
  obj = Object.getOwnPropertyNames;

  constructor(private authService: AuthService, private userService: UserService, private alertService: AlertService,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.buildForm();
  }

  get authenticated(): boolean {
    return this.authService.authenticated;
  }

  /**
 * @method buildForm()
 * @desc used to build form.
 */
  buildForm(): void {
    this.loginForm = this.formBuilder.group({
      email: [this.email,
      [Validators.required, COMMONFORMVALIDATIONRULE.emailPattern]],
    });
  }

  login(): void {
    this.isLoading = true;
    this.userService.loginUser(this.loginForm.value.email).subscribe(
      res => {
        this.isLoading = false;
        if (res.statusCode === 200) {
          this.redirectToAzure(this.loginForm.value.email);
        }
      },
      error => {
        this.isLoading = false;
        this.alertService.error(error.error.title)
      }
    );
  }

  async redirectToAzure(email): Promise<void> {
    await this.authService.signIn(email);
  }
}
