<ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar"
    [ngClass]="{ 'active': topser.hideSideNav }">
    <a class="sidebar-brand d-flex align-items-center justify-content-center  justify-side">

        <div class="img-assets"><img src="assets/images/sidebar_logo.svg"></div>
    </a>
    <a id="hide_cross_icon" (click)="hideSideNav()"><i class="fa fa-times"></i></a>

    <li class="nav-item nav-item1">
        <a class="nav-link collapsed" routerLink="/dashboard" routerLinkActive="activeTab" data-toggle="collapse"
            data-target="#collapseProduct" aria-expanded="true" aria-controls="collapseProduct">
            <i class=" sideMenuIcons fa fa-home" aria-hidden="true"></i>
            <span>Dashboard</span>
        </a>
    </li>

    <li *ngIf="roleId==='4'" class="nav-item">
        <hr class="sidebar-divider">
        <a class="nav-link usermanagement_arrow collapsed" data-toggle="collapse" routerLinkActive="activeTab"
            data-target="#collapseTwo" aria-expanded="true">
            <i class="sideMenuIcons fa fa-users" aria-hidden="true"></i>
            <span>User Management</span>
        </a>
        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
            <div class="collapse-inner rounded">
                <a class="collapse-item" routerLink="/ax-user">AX User</a>
            </div>
            <div class="collapse-inner rounded">
                <a class="collapse-item" routerLink="/dealer-users">Dealer Users</a>
            </div>
            <div class="collapse-inner rounded">
                <a class="collapse-item" routerLink="/other-user">Other User</a>
            </div>
        </div>
    </li>
    <li class="nav-item">
        <hr class="sidebar-divider">
        <a class="nav-link usermanagement_arrow collapsed" data-toggle="collapse" data-target="#collapseUtilities"
            aria-expanded="true" aria-controls="collapseUtilities">
            <img src="assets/images/Iconawesome-box-open.svg" class="nav-pad">
            <span>My Orders</span>
        </a>
        <div id="collapseUtilities" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
            <div class="collapse-inner rounded">
                <a class="collapse-item" routerLink="/pending-approvals">Pending Approvals</a>
            </div>

            <div class="collapse-inner rounded">
                <a class="collapse-item" routerLink="/all-orders">All orders</a>
            </div>
        </div>
    </li>

    <li *ngIf="roleId==='4'" class="nav-item">
        <hr class="sidebar-divider">
        <a class="nav-link collapsed" routerLink="/product" routerLinkActive="activeTab" data-toggle="collapse"
            data-target="#collapseProduct" aria-expanded="true" aria-controls="collapseProduct">
            <img src="assets/images/Group967.svg" class="nav-pad1">
            <span>Product Management</span>
        </a>
    </li>

    <!-- <li *ngIf="roleId==='4'" class="nav-item">
        <hr class="sidebar-divider">
        <a class="nav-link collapsed" data-toggle="collapse" data-target="#collapseProduct" aria-expanded="true"
            aria-controls="collapseProduct">
            <img src="assets/images/postingAx.svg" class="nav-pad3">
            <span>Posting to AX</span>
        </a>
    </li>
    <li *ngIf="roleId==='4'" class="nav-item">
        <hr class="sidebar-divider">
        <a class="nav-link collapsed" routerLink="/notifications" data-toggle="collapse" data-target="#collapseProduct"
            aria-expanded="true" aria-controls="collapseProduct">
            <img class="navnotificaion" src="assets/images/notification_icon (1).svg">
            <span>Notification Management</span>
        </a>
    </li> -->

    <li *ngIf="roleId==='4'" class="nav-item">
        <hr class="sidebar-divider">
        <a class="nav-link collapsed" routerLink="/reject-reasons" routerLinkActive="activeTab" data-toggle="collapse"
            data-target="#collapseProduct" aria-expanded="true" aria-controls="collapseProduct">
            <i class="sideMenuIcons fa fa-window-close-o" aria-hidden="true"></i>
            <span>Reject Reasons</span>
        </a>
    </li>

    <li *ngIf="roleId==='4'" class="nav-item nav-item1">
        <hr class="sidebar-divider">
        <a class="nav-link collapsed" routerLink="/reports" routerLinkActive="activeTab" data-toggle="collapse"
            data-target="#collapseProduct" aria-expanded="true" aria-controls="collapseProduct">
            <i class="sideMenuIcons fa fa-file-excel-o" aria-hidden="true"></i>
            <span>Reports</span>
        </a>
    </li>
</ul>