import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './Feature/home/home.component';
import { AdminLoginComponent } from './Auth/admin-login/admin-login.component';
import { AdminOrderManagementComponent } from './Feature/admin-ordermanagement/admin-ordermanagement.component';
import { ProfileComponent } from './Feature/profile/profile.component';
import { AdminProductManagementComponent } from './Feature/admin-product-management/admin-product-management.component';
import { AddUserComponent } from './Feature/adduser/adduser.component';
import { PendingOrdersComponent } from './Feature/pending-orders/pending-orders.component';

import { OtherUserComponent } from './Feature/otheruser/otheruser.component';
import { AdminUserManagement } from './Feature/admin-usermanagement/admin-usermanagement';
import { DealerUserManagement } from './Feature/dealer-usermanagement/dealer-usermanagement';
import { ProductDetailsComponent } from './Feature/product-details/product-details.component';
import { ReasonsListComponent } from './Feature/reasons-list/reasons-list.component';
import { AddReasonsComponent } from './Feature/add-reasons/add-reasons.component';
import { NotificationsComponent } from './Feature/notifications/notifications.component';
import { FlowGuard, LoginFlowGuard } from './guards/flow.gurad';
import { EditNotificationsComponent } from './Feature/edit-notifications/edit-notifications.component'
import { ReportsComponent } from './Feature/reports/reports.component';
import { UserMasterReportComponent } from './Feature/user-master-report/user-master-report.component';
import { ProductMasterReportComponent } from './Feature/product-master-report/product-master-report.component';
import { OrderReportComponent } from './Feature/order-report/order-report.component';
import { ActivityReportComponent } from './Feature/activity-report/activity-report.component';
import { CustomerLedgerComponent } from './Feature/customer-ledger/customer-ledger.component';
import { RedirectComponent } from './Feature/redirect-component/redirect-component.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [LoginFlowGuard],
    children: [
      { path: '', component: AdminLoginComponent },
      { path: 'login', component: AdminLoginComponent },
      { path: 'id_token', component: RedirectComponent },
    ]
  },
  {
    path: '',
    canActivate: [FlowGuard],
    children: [
      { path: 'dashboard', component: HomeComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'product', component: AdminProductManagementComponent },
      { path: 'product-details/:id', component: ProductDetailsComponent },
      { path: 'user/:userCode', component: AddUserComponent },
      { path: 'add-user', component: AddUserComponent },
      { path: 'all-orders', component: AdminOrderManagementComponent },
      { path: 'all-orders/:fromDate/:toDate/:orderStatus', component: AdminOrderManagementComponent },
      { path: 'pending-approvals', component: PendingOrdersComponent },
      { path: 'pending-approvals/:fromDate/:toDate', component: PendingOrdersComponent },
      { path: 'ax-user', component: AdminUserManagement },
      { path: 'dealer-users', component: DealerUserManagement },
      { path: 'other-user', component: OtherUserComponent },
      { path: 'reject-reasons', component: ReasonsListComponent },
      { path: 'add-reason', component: AddReasonsComponent },
      { path: 'reason/:reasonId', component: AddReasonsComponent },
      { path: 'notifications', component: NotificationsComponent },
      { path: 'edit-notifications', component: EditNotificationsComponent },
      { path: 'customer-ledger', component: CustomerLedgerComponent },
      { path: 'user-master-report', component: UserMasterReportComponent },
      { path: 'product-master-report', component: ProductMasterReportComponent },
      { path: 'order-report', component: OrderReportComponent },
      { path: 'user-activity-report', component: ActivityReportComponent },
      { path: 'reports', component: ReportsComponent },
      { path: 'id_token', component: RedirectComponent },
      { path: 'redirect', component: RedirectComponent },
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
