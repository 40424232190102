<div class='modal-content'>
    <div class='modal-header'>{{subject}}
        <button type="button" class="close" data-dismiss="modal" (click)="activeModal.close()">
            <span aria-hidden='true'>×</span>
        </button>
    </div>
    <div class='modal-body'>
        <h3>{{message}}</h3>
        <label *ngIf="action==='rejectOrder'"  for="name">Select Reject Reason <span id="asterisk_red">*</span></label>
        <select *ngIf="action==='rejectOrder'" [(ngModel)]="rejectId" class="form-control" id="select1" name="rejectId">
            <option selected disabled value="0">Select Reject Reason </option>
            <option *ngFor="let reason of reasonsList" value="{{reason.id}}" innerHTML="{{reason.reason}}"></option>
        </select>
        <div class="has-error" *ngIf="action==='rejectOrder' && showErrorMsg">
            <div class="help-block">
                {{rejectIdRequired}}</div>
        </div>
        <p class="mt-3">Write a comment here</p>
        <textarea id="text_area_reject" rows="4" cols="50" [(ngModel)]="comment" name="comment" form="usrform"
            title="{{errorMsg.MAX_LENGTH250}}" maxlength="250"></textarea>
    </div>
    <div class="modal-footer"><button type="button" (click)="cancel()" class="btn btn-secondary">No</button>
        <a class="btn btn-primary" (click)='confirm()'>Yes</a></div>

</div>