<div class='modal-content'>
    <div class="modal-header">ORDER DETAILS
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class='modal-body'>
        <table class="table detailsTable">
            <thead>
                <tr>
                    <th scope="col">Order Id :-</th>
                    <th scope="col">{{modalObject?.order_number}}</th>
                    <th scope="col">Dealer Code :-</th>
                    <th scope="col">{{modalObject?.dealer_code}}</th>
                    <th scope="col">Dealer Name :-</th>
                    <th scope="col">{{modalObject?.dealer_name}}</th>
                </tr>

            </thead>
        </table>

        <table class="table detailsTable">
            <thead>
                <tr>
                    <th scope="col">Item Code</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Product Type</th>
                    <th scope="col">Qty</th>
                    <th scope="col">Price per Unit</th>
                    <th scope="col">Amount</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let orderDetails of modalObject.orderDetails">
                    <td>{{orderDetails?.product_Id}}</td>
                    <td>{{orderDetails?.product_name}}</td>
                    <td>{{orderDetails?.productTypeName}}</td>
                    <td>{{orderDetails?.order_qty}}</td>
                    <td>{{orderDetails?.product_rate}}</td>
                    <td>{{orderDetails?.order_amount}}</td>
                </tr>
                <tr>
                    <th colspan="4"></th>
                    <td class="fontWeight">Total Amount</td>
                    <td class="fontWeight">{{modalObject?.total_Amount}}</td>
                </tr>
            </tbody>
        </table>
        <div class="text-right col-md-12">
            <div *ngIf="action==='pending-orders'">
                <button (click)="acceptOrder(modalObject?.order_number)"
                    class="btn btn-primary pd-upload">Accept</button>
                <button (click)="rejectOrder(modalObject?.order_number)"
                    class="btn btn-primary pd-upload">Reject</button>
            </div>
        </div>
    </div>
</div>