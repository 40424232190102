import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TopnavService {
  hideSideNav: boolean = false;
  constructor() { }

  toggleSideNav(): void {
    this.hideSideNav = !this.hideSideNav;
  }

  setDataInStorage(key, value): any {
    localStorage.setItem(key, value);
  }

  getDataFromStorage(key): any {
    let data = localStorage.getItem(key);
    return data;
  }


}
