export const range = (start, end) => Array.from({ length: (end - start) }, (v, k) => k + start);

export class PagerService {
    getPager(totalItems: number, currentPage: number = 1, pageSize: number = 8) {

        // calculate total pages
        let totalPages = Math.ceil(totalItems / pageSize);

        if (totalPages == 0) {
            totalPages = 1;
        }

        let startPage: number, endPage: number;
        if (totalPages <= 10) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = currentPage - 5;
                endPage = currentPage + 4;
            }
        }

        // calculate start and end item indexes
        let startIndex = (currentPage - 1) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        /*if (endIndex < 0) {
            endIndex = 0;
        }*/

        // create an array of pages to ng-repeat in the pager control
        let pages = range(startPage, endPage + 1);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }
}