const accountVersion = 'Account/';
const productVersion = 'Product/';
const orderVersion = 'Order/';
const userVersion = 'User/';
const territoryVersion = 'Territory/';
const reportVersion = 'Report/';

export const API_ENDPOINTS = {
    LOGIN: accountVersion + 'AuthenticateUser/',
    USER_DETAILS: accountVersion + 'UserDetail/',
    GET_PROFILE: accountVersion + 'GetProfile/',
    SAVE_PROFILE: accountVersion + 'SaveProfile',
    GET_PRODUCT: productVersion + 'product-list',
    SAVE_PRODUCT: productVersion + 'SaveProduct',
    GET_TERRITORY: territoryVersion + 'get-territories',
    GET_ALL_ORDERS: orderVersion + 'get-orders',
    GET_USERS: accountVersion + 'user-list',
    SAVE_USER: accountVersion + 'save-otheruser',
    GET_REJECT_REASONS: orderVersion + 'get-reject-reason',
    GET_PENDING_ORDERS: orderVersion + 'get-pending-orders',
    GET_PRODUCT_TYPES: productVersion + 'get-productType-list',
    APPROVE_ORDER: orderVersion + 'orders-approval',
    SAVE_REJECT_REASON: orderVersion + 'save-reject-reason',
    DELETE_REJECT_REASON: orderVersion + 'delete-reject-reason',
    GET_ORDER_STATUS: orderVersion + 'get-order-status',
    GET_ORDER_REPORT: reportVersion + 'get-order-activity-report',
    GET_CUSTOMER_LEDGER_REPORT: orderVersion + 'get-customer-ledgerpdf',
    GET_USER_REPORT: reportVersion + 'get-user-activity-report',
    GET_USER_MASTER_REPORT: reportVersion + 'get-userlist-report',
    GET_PRODUCT_MASTER_REPORT: reportVersion + 'get-product-master-report',
    GET_ORDER_COUNT: reportVersion + 'order-status-count',
    GET_SALES_DATA: reportVersion + 'sales-report'
};
