<div id="wrapper">
  <app-sidebar></app-sidebar>
  <div *ngIf="isLoading" id="mydiv">
    <img src="../../../assets/images/Spinner.gif" class="ajax-loader" />
  </div>
  <div id="content-wrapper" class="d-flex flex-column">
    <div id="content">
      <app-topnav></app-topnav>
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/reports">Reports</a></li>
        <li class="breadcrumb-item"><a id="reports_bcum_col">Transactions</a></li>
        <li class="breadcrumb-item"><a id="pendingapr">User Activity Report</a></li>
      </ol>
      <div class="card-header">User Activity Report</div>
      <div class="container-fluid">
        <form [formGroup]="userActivityReport" class="bgWhite">
          <div class="order-report-container">
            <div class="form-group row">
              <div class="col-md-12">
                <label class="col-form-label from_date" for="text-input">From Date<span id="asterisk_red">*</span>:
                </label>
                <mat-form-field color="primary">
                  <mat-label>Choose from date</mat-label>
                  <input matInput #startDate formControlName="startDate" [max]="maxDate" [matDatepicker]="pickerFrom"
                    (dateChange)="onStartDateChanged($event)" disabled>
                  <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                  <mat-datepicker #pickerFrom disabled="false"></mat-datepicker>
                </mat-form-field>
                <div class="has-error"
                  *ngIf="userActivityReport.controls['startDate'].errors && !userActivityReport.controls['startDate'].pristine">
                  <div [ngSwitch]="obj(userActivityReport.controls['startDate'].errors)[0]">
                    <div *ngSwitchCase="'required'" class="help-block">
                      {{errorMsg.REQUIRED}}</div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <label class="col-form-label to_date" for="text-input">To Date<span id="asterisk_red">*</span><span
                    id="vis__hid"></span>: </label>
                <mat-form-field color="primary">
                  <mat-label>Choose to date</mat-label>
                  <input matInput #endDate formControlName="endDate" [max]="maxDate" [matDatepicker]="pickerTo"
                    (dateChange)="onEndDateChanged($event)" disabled>
                  <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                  <mat-datepicker #pickerTo disabled="false"></mat-datepicker>
                </mat-form-field>
                <div class="has-error"
                  *ngIf="userActivityReport.controls['endDate'].errors && !userActivityReport.controls['endDate'].pristine">
                  <div [ngSwitch]="obj(userActivityReport.controls['endDate'].errors)[0]">
                    <div *ngSwitchCase="'required'" class="help-block">
                      {{errorMsg.REQUIRED}}</div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="leader_select">
                  <label class="col-form-label" for="text-input">Select User<span id="vis__hidtwo"></span>: </label>
                  <div class="select_field">
                    <mat-form-field>
                      <mat-select placeholder="Select User" #usersList formControlName="usersList" name="usersList"
                        [compareWith]="compareFn">
                        <app-mat-select-search [formControl]="itemMultiFilterCtrl"></app-mat-select-search>
                        <mat-option value=""> All </mat-option>
                        <mat-option *ngFor="let user of filteredItemsMulti | async; trackBy:trackByFn;"
                          value="{{user.emp_code}}"> {{user.emp_name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button type="button" color="primary" [disabled]="userActivityReport.invalid || canDownload" rounded="true" (click)="getUserReport()"
            class="btn btn-primary save-btn">Download</button>
        </form>
      </div>
    </div>
  </div>