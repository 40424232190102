<div id="wrapper">
    <app-sidebar></app-sidebar>
    <div *ngIf="isLoading" id="mydiv">
        <img src="../../../assets/images/Spinner.gif" class="ajax-loader" />
    </div>
    <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
            <app-topnav></app-topnav>
            <div class="scroll_vertical">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a>Dashboard</a>
                    </li>
                </ol>

                <div class="card-header dashboard_header">
                    <div class="Orders-home">
                        Orders
                    </div>

                    <div class="day-month-year">
                        <div class="btn-group btn-group-toggle" data-toggle="buttons">
                            <button btnradio="Day" class="btn btn-outline-secondary ng-valid active ng-touched ng-dirty"
                                [ngClass]="{'active': dateValue==='today'}" id="option1" aria-pressed="false"
                                (click)="onDateFilterChanged('today')">Today</button>
                            <button btnradio="Month" class="btn btn-outline-secondary ng-valid ng-touched ng-dirty"
                                id="option2" name="month" aria-pressed="false" (click)="onDateFilterChanged('month')"
                                [ngClass]="{'active': dateValue==='month'}">This Month</button>
                            <button btnradio="Year" class="btn btn-outline-secondary ng-valid ng-touched ng-dirty"
                                id="option3" name="year" aria-pressed="false" (click)="onDateFilterChanged('year')"
                                [ngClass]="{'active': dateValue==='year'}">This Year</button>
                        </div>
                    </div>
                    <div class="day-month-year">
                        <div class="btn-group btn-group-toggle " data-toggle="buttons">
                            <button btnradio="Month" class="btn btn-outline-secondary ng-valid ng-touched ng-dirty"
                                [ngClass]="{'active': lob==='cp'}" id="option2" (click)="lobChanged('cp')"
                                aria-pressed="false">CP</button>
                            <button btnradio="Year" class="btn btn-outline-secondary ng-valid ng-touched ng-dirty"
                                [ngClass]="{'active': lob==='seed'}" id="option3" (click)="lobChanged('seed')"
                                aria-pressed="false">Seeds</button>
                        </div>
                    </div>
                </div>
                <div class="container-fluid">
                    <form class="form-group form-inline">
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-3">
                                <div routerLink="/pending-approvals/{{startDate}}/{{endDate}}"
                                    class="cursor card text-white  bg-danger ">
                                    <div class="card-body pb-0">
                                        <img id="dashboard_png_img" src="assets/images/Group1.svg">
                                        <div class="count-pending">{{orderCount.pending}}</div>
                                        <div class="text-value">Pending</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-3">
                                <div routerLink="/all-orders/{{startDate}}/{{endDate}}/13"
                                    class="cursor card text-white  bg-warning">
                                    <div class="card-body pb-0">
                                        <img id="dashboard_png_img" src="assets/images/Group2.svg">
                                        <div class="count-approved">{{orderCount.approved}}</div>
                                        <div class="text-value">Approved</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-3">
                                <div routerLink="/all-orders/{{startDate}}/{{endDate}}/2"
                                    class="cursor card text-white bg-primary ">
                                    <div class="card-body pb-0">
                                        <img id="dashboard_png_img" src="assets/images/Group3.svg">
                                        <div class="count-dispatched">{{orderCount.dispatched}}</div>
                                        <div class="text-value">Dispatched</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-3">
                                <div routerLink="/all-orders/{{startDate}}/{{endDate}}/3"
                                    class="cursor card text-white bg-info">
                                    <div class="card-body pb-0">
                                        <img id="dashboard_png_img" src="assets/images/Group4.svg">
                                        <div class="count-invoice">{{orderCount.invoiced}}</div>
                                        <div class="text-value">Invoice</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-header sales_dashboard">
                    <div class="Orders-home">
                        Sales
                    </div>
                    <div class="day-month-year">
                        <div class="btn-group btn-group-toggle" data-toggle="buttons">
                            <label btnradio="Day" [ngClass]="{'active': saleDateValue==='today'}"
                                class="btn btn-outline-secondary ng-valid ng-touched ng-dirty" id="option1"
                                aria-pressed="false" (click)="onSaleDateFilterChanged('today')">Today</label>
                            <label btnradio="Month" [ngClass]="{'active': saleDateValue==='month'}"
                                class="btn btn-outline-secondary ng-valid ng-touched ng-dirty" id="option2"
                                aria-pressed="false" (click)="onSaleDateFilterChanged('month')">This Month</label>
                            <label btnradio="Year" [ngClass]="{'active': saleDateValue==='year'}"
                                class="btn btn-outline-secondary ng-valid ng-touched ng-dirty" id="option3"
                                aria-pressed="false" (click)="onSaleDateFilterChanged('year')">This Year</label></div>
                    </div>
                    <div class="day-month-year">
                        <div class="btn-group btn-group-toggle  " data-toggle="buttons">
                            <label btnradio="Month" [ngClass]="{'active': searchTypeValue==='V'}"
                                class="btn btn-outline-secondary ng-valid ng-touched ng-dirty" id="option2"
                                aria-pressed="false" (click)="onSearchTypeChanged('V')">Vol</label>
                            <label btnradio="Year" [ngClass]="{'active': searchTypeValue==='R'}"
                                class="btn btn-outline-secondary ng-valid active ng-touched ng-dirty" id="option3"
                                aria-pressed="false" (click)="onSearchTypeChanged('R')">Rev</label></div>
                    </div>
                    <div class="seeds-sp">
                        <div class="form-group row">
                            <label class="col-md-4 col-form-label" for="select1">Select Product Type</label>
                            <div class="col-md-8">
                                <select name="productType" (change)="onProductTypeChange(productType)"
                                    [(ngModel)]="productType" class="form-control" id="select1" name="select1">
                                    <option value="0">All</option>
                                    <option *ngFor="let type of productTypeList" value="{{type.id}}">
                                        {{type.product_type_name}}
                                    </option>
                                </select></div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid">
                    <form>
                        <div class="text-center" *ngIf="salesData?.sales?.length===0">
                            {{errorMsg.NO_RECORD_FOUND}}</div>
                        <div class="row mt-0">
                            <div *ngIf="chartOptions.scales.yAxes[0].ticks.max && salesData?.sales?.length > 0"
                                style="width: 90%;height:350px;background-color: #fff;padding:25px;">
                                <p *ngIf="searchTypeValue==='R'">Total Amount (In Rs.)</p>
                                <p *ngIf="searchTypeValue==='V'">Total Quantity Units</p>
                                <canvas baseChart [data]="chartData" [legend]="true" [labels]="chartLabels"
                                    [options]="chartOptions" [colors]="lineChartColors" [chartType]="'line'"
                                    style="display: block;width: 800px;height: 400px;" [legend]="false">
                                </canvas>
                                <p class="text-right">Products</p>
                            </div>
                        </div>
                    </form>
                </div>
                <footer class='container-fluid bg-4 text-center'>
                    <p>Corteva web <a href=''>www.corteva.com</a></p>
                </footer>
            </div>
        </div>

    </div>
</div>