<div class='modal-content'>
    <div class='modal-header'>
        <h4 class='modal-title mb-0' id='exampleModalLabel'><b>{{subject}}</b></h4>
        <button type="button" class="close" data-dismiss="modal" (click)="activeModal.close()">
            <span aria-hidden='true'>×</span>
        </button>
    </div>
    <div style="word-break: break-word;" class='modal-body'>{{message}}
    </div>
    <div *ngIf="action !== 'territoryAssigned'" class='modal-footer'>
        <button class='btn btn-secondary' type='button' (click)="cancel()">No</button>
        <a class='btn btn-primary' (click)='confirm()'>Yes</a>
    </div>
</div>