import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { OrderService } from '../../services/order.service';
import { AlertService } from '../../utilities/alert';
import { ERROR_MESSAGES } from '../../utilities/constants';
@Component({
  selector: 'app-add-reasons',
  templateUrl: './add-reasons.component.html',
  styleUrls: ['./add-reasons.component.scss']
})
export class AddReasonsComponent implements OnInit {
  titleValue = 'Add Reason';
  userId: any;
  reasonId: any;
  reasonForm: FormGroup;
  reasonDetails: any = {
    reasonDescription: ''
  };
  obj = Object.getOwnPropertyNames;
  errorMsg = ERROR_MESSAGES;
  saveInProgress = false;
  isLoading = false;

  constructor(private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private orderService: OrderService, private alertService: AlertService) { }

  ngOnInit(): void {
    this.userId = localStorage.getItem('userId');
    this.buildForm();
    this.reasonId = this.route.snapshot.params.reasonId;
    if (this.reasonId) {
      this.getReasonById();
    }
  }

  /**
  * @method buildForm()
  * @desc used to build form.
  */
  buildForm(): void {
    this.reasonForm = this.formBuilder.group({
      reasonDescription: [this.reasonDetails.reasonDescription, [Validators.required, Validators.maxLength(100),]],
    });
  }

  getReasonById(): void {
    this.isLoading = true;
    this.titleValue = 'Edit Reason';
    this.orderService.getReasonById(this.reasonId).subscribe(
      res => {
        this.isLoading = false;
        this.reasonDetails = res.rejectReason[0];
        this.reasonForm.patchValue({
          reasonDescription: this.reasonDetails.reason ? this.reasonDetails.reason : '',
        });
      },
      error => {
        this.isLoading = false;
        this.alertService.error(error.error.title)
      }
    );
  }

  saveRejectReasons(data): void {
    this.isLoading = true;
    this.saveInProgress = true;
    let payload: any = {
      reasonname: this.reasonForm.value.reasonDescription,
      createdby: this.userId
    }
    if (this.reasonId) {
      payload.id = data.id;
    }
    this.orderService.saveRejectReasons(payload).subscribe(
      res => {
        this.isLoading = false;
        this.saveInProgress = false;
        if (res.statusCode == 200) {
          this.router.navigate(['/reject-reasons']);
          this.alertService.success(this.errorMsg.REASON_SAVED);
        }
      },
      error => {
        this.isLoading = false;
        this.saveInProgress = false;
        this.alertService.error(error.error.title)
      }
    );
  }

}
