<div id="wrapper">
  <app-sidebar></app-sidebar>
  <div *ngIf="isLoading" id="mydiv">
        <img src="../../../assets/images/Spinner.gif" class="ajax-loader" />
    </div>
  <div id="content-wrapper" class="d-flex flex-column">
    <div id="content">
      <app-topnav></app-topnav>
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a>User Management</a></li>
        <li class="breadcrumb-item"><a id="other__user">Other User</a>
        </li>
        <li class="breadcrumb-menu d-md-down-none"></li>
      </ol>
      <div class="card-header"> Other User 
       <div class="form-group form-inline form-io">
                <button type="button" color="primary" rounded="true" mdbwaveseffect="" class="btn btn-primary save-btn"
              routerLink="/add-user">Add User</button>
          </div>
    
      </div>
      <div class="container-fluid">


        <form class="ng-untouched ng-pristine ng-valid">
         

          <table [dtTrigger]="dtTrigger" class="table table-striped table-striped-in" datatable [dtOptions]="dtOptions">
            <thead>
              <tr>
                <th>Code</th>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile Number</th>
                <th>Status</th>
                <th>Joining Date </th>
                <th>Role</th>
                <!-- <th>Activity</th> -->
                <th class="bgImage">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let otherUser of otherUserList;let i=index">
                <td>{{otherUser?.emp_code}}</td>
                <td>{{otherUser?.emp_name}}</td>
                <td>{{otherUser?.emp_email}}</td>
                <td>{{otherUser?.emp_phone}}</td>
                <td>{{otherUser?.status}}</td>
                <td>{{otherUser?.joining_date | date: 'dd/MMM/yyyy'}}</td>
                <td>{{otherUser?.role}}</td>
                <!-- <td></td> -->
                <td routerLink="/user/{{otherUser.emp_code}}">
                  <div>
                    <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                  </div>

                </td><span></span>
              </tr>

            </tbody>
          </table>
        </form>
      </div>
    </div>
  </div>
</div>