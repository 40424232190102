import { Component, OnInit, ViewChild, OnChanges } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';

import { ReportService } from '../../services/report.service';
import { AlertService } from '../../utilities/alert/alert.service';
import { ProductService } from 'src/app/services/product.service';
import { ERROR_MESSAGES } from '../../utilities/constants';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild(BaseChartDirective)
  public chart: BaseChartDirective;
  errorMsg = ERROR_MESSAGES;
  startDate: any = '';
  endDate: any = new Date();
  saleStartDate: any = ''
  saleEndDate: any = new Date();
  userCode: any = '';
  lob: any = 'seed';
  saleLob: any = 'seed';
  orderCount: any = '';
  dateValue: any = 'month';
  saleDateValue = 'month';
  isLoading = false;
  productTypeList: any = [];
  productType: any = '0';
  salesData: any = '';
  searchTypeValue: any = 'R';

  chartData = [];
  chartLabels = [];

  chartOptions = {
    data: this.chartData,
    responsive: true,
    tooltips: {
      enabled: true,
      callbacks: {
        label: function (tooltipItems) {
          if (isNaN(tooltipItems.yLabel)) return tooltipItems.yLabel;
          if (tooltipItems.yLabel < 9999) {
            return tooltipItems.yLabel;
          }
          if (tooltipItems.yLabel < 1000000) {
            return Math.round(tooltipItems.yLabel / 1000) + "K";
          }
          if (tooltipItems.yLabel < 10000000) {
            return (tooltipItems.yLabel / 1000000).toFixed(2) + "M";
          }
          if (tooltipItems.yLabel < 1000000000) {
            return Math.round((tooltipItems.yLabel / 1000000)) + "M";
          }
          if (tooltipItems.yLabel < 1000000000000) {
            return Math.round((tooltipItems.yLabel / 1000000000)) + "B";
          }
        }
      }
    },
    scales: {
      yAxes: [
        {
          display: true,
          position: 'left',
          ticks: {
            beginAtZero: true,
            callback: data => {
              if (isNaN(data)) return data;
              if (data < 9999) {
                return data;
              }
              if (data < 1000000) {
                return Math.round(data / 1000) + "K";
              }
              if (data < 10000000) {
                return (data / 1000000).toFixed(2) + "M";
              }
              if (data < 1000000000) {
                return Math.round((data / 1000000)) + "M";
              }
              if (data < 1000000000000) {
                return Math.round((data / 1000000000)) + "B";
              }
            },
            max: null
          }
        }]
    },
    maintainAspectRatio: false
  };

  public lineChartColors: Array<any> = [
    {
      backgroundColor: 'rgba(255, 255, 255, .1)',
      borderColor: '#4e73df',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];


  constructor(private productService: ProductService, private reportService: ReportService, private alertService: AlertService) {

  }

  ngOnInit(): void {
    this.userCode = localStorage.getItem('userId');
    this.startDate = this.saleStartDate = this.endDate.getFullYear() + "-" + ("0" + (this.endDate.getMonth() + 1)).slice(-2) + "-" + ("0" + (0 + 1)).slice(-2);
    this.endDate = this.saleEndDate = this.endDate.getFullYear() + "-" + ("0" + (this.endDate.getMonth() + 1)).slice(-2) + "-" + ("0" + (this.endDate.getDate())).slice(-2);
    if (this.userCode) {
      this.getSalesReport();
      this.getOrderCount();
      this.getProductTypes();
    }
  }

  lobChanged(lob) {
    setTimeout(() => {
      this.lob = lob;
      this.orderCount = '';
      this.getOrderCount();
    }, 20);
  }

  getProductTypes(): void {
    this.productService.getProductTypes(this.lob).subscribe(
      res => {
        this.productTypeList = res.productType;
      },
      error => {
        this.alertService.error(error.error.title)
      }
    );
  }

  onDateFilterChanged(value) {
    this.orderCount = ''
    setTimeout(() => {
      if (value === 'today') {
        this.dateValue = value;
        let date = new Date();
        this.startDate = date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + (date.getDate())).slice(-2);
        this.endDate = date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + (date.getDate())).slice(-2)
      } else if (value === 'month') {
        this.dateValue = value;
        let date = new Date();
        this.startDate = date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + (0 + 1)).slice(-2);
        this.endDate = date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + (date.getDate())).slice(-2);
      } else {
        this.dateValue = value;
        let date = new Date();
        this.startDate = date.getFullYear() + "-" + ("0" + (0 + 1)).slice(-2) + "-" + ("0" + (0 + 1)).slice(-2);
        this.endDate = date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + (date.getDate())).slice(-2)
      }
      this.orderCount = '';
      this.getOrderCount();
    }, 30);
  }

  onSaleDateFilterChanged(value) {
    this.orderCount = ''
    setTimeout(() => {
      if (value === 'today') {
        this.saleDateValue = value;
        let date = new Date();
        this.saleStartDate = date.getFullYear() + "-" + ("0" + (date.getMonth())).slice(-2) + "-" + ("0" + (date.getDate())).slice(-2);
        this.saleEndDate = date.getFullYear() + "-" + ("0" + (date.getMonth())).slice(-2) + "-" + ("0" + (date.getDate())).slice(-2)
      } else if (value === 'month') {
        this.saleDateValue = value;
        let date = new Date();
        this.saleStartDate = date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + (0 + 1)).slice(-2);
        this.saleEndDate = date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + (date.getDate())).slice(-2)
      } else {
        this.saleDateValue = value;
        let date = new Date();
        this.saleStartDate = date.getFullYear() + "-" + ("0" + (0 + 1)).slice(-2) + "-" + ("0" + (0 + 1)).slice(-2);
        this.saleEndDate = date.getFullYear() + "-" + ("0" + (date.getMonth())).slice(-2) + "-" + ("0" + (date.getDate())).slice(-2)
      }
      this.salesData = {};
      this.getSalesReport();
    }, 30);
  }


  getOrderCount(): void {
    this.isLoading = true;
    var payload = {
      fromdate: this.startDate,
      todate: this.endDate,
      userCode: this.userCode,
      lob: this.lob
    }
    this.reportService.getOrderCount(payload).subscribe(
      res => {
        this.isLoading = false;
        if (res.statusCode == 200) {
          this.orderCount = res;

        }
      },
      error => {
        this.isLoading = false;
        this.alertService.error(error.error.title)
      }
    );
  }

  onProductTypeChange(value) {
    this.productType = value;
    this.getSalesReport();
  }

  onSearchTypeChanged(item) {
    this.searchTypeValue = item;
    this.getSalesReport();
  }

  getSalesReport(): void {
    this.isLoading = true;
    var payload = {
      fromdate: this.saleStartDate,
      todate: this.saleEndDate,
      createdBy: this.userCode,
      lob: this.saleLob,
      productType: this.productType,
      searchType: this.searchTypeValue
    }
    this.reportService.getSalesReport(payload).subscribe(
      res => {
        this.isLoading = false;
        if (res.statusCode == 200) {
          this.salesData = res;
          this.chartOptions.scales.yAxes[0].ticks.max = null;
          this.chartData = [];
          this.chartLabels = [];
          if (this.searchTypeValue === 'R' && this.salesData.sales.length > 0) {
            setTimeout(() => {
              this.salesData.sales.forEach(item => {
                this.chartOptions.scales.yAxes[0].ticks.max = this.salesData.maxValue;
                this.chartData.push(item.totalAmount);
                this.chartLabels.push(item.productId);
              });
            }, 50);

          }
          if (this.searchTypeValue === 'V' && this.salesData.sales.length > 0) {
            setTimeout(() => {
              this.salesData.sales.forEach(item => {
                this.chartOptions.scales.yAxes[0].ticks.max = this.salesData.maxValue;
                this.chartData.push(item.totalQty);
                this.chartLabels.push(item.productId);
              })
            }, 50);

          }

        }
      },
      error => {
        this.isLoading = false;
        this.alertService.error(error.error.title)
      }
    );
  }
}
