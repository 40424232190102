<div id="wrapper">
  <app-sidebar></app-sidebar>
  <div id="content-wrapper" class="d-flex flex-column">
    <div id="content">
      <app-topnav></app-topnav>
      <div class="scroll_vertical">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a>Reports</a>
          </li>
        </ol>
        <div class="reports_container">
          <div class="report_flex">
            <div class="card-header dashboard_header">
              <div class="Orders-home">
                Masters
              </div>
            </div>


            <div class="container-fluid">
              <form class="form-group form-inline">
                <div class="day-month-year">
                  <div class="btn-group btn-group-toggle" data-toggle="buttons">

                    <button class="btn btn-outline-secondary ng-valid active ng-touched ng-dirty" id="option1"
                      aria-pressed="false" routerLink="/user-master-report">User Report</button>


                    <button class="btn btn-outline-secondary ng-valid ng-touched ng-dirty" id="option2"
                      aria-pressed="false" routerLink="/product-master-report">Product Report</button>

                  </div>
                </div>
              
               <ul id="master__reports">
                <a href="#/user-master-report"><li>User Report</li></a>
                <a href="#/product-master-report"><li>Product Report</li></a>
              </ul>
              
              
              
              </form>
             
              
            </div>
          </div>

          <div class="report_flex">
            <div class="card-header sales_dashboard">
              <div class="Orders-home">
                Transactions
              </div>
            </div>
            <div class="container-fluid">
              <form class="form-group form-inline">
                <div class="day-month-year">
                  <div class="btn-group btn-group-toggle" data-toggle="buttons">
                    <button class="btn btn-outline-secondary ng-valid active ng-touched ng-dirty" id="option1"
                      aria-pressed="false" routerLink="/order-report">Order Report</button>
                    <button class="btn btn-outline-secondary ng-valid ng-touched ng-dirty" id="option2"
                      aria-pressed="false" routerLink="/customer-ledger">Customer Ledger Report</button>
                    <button class="btn btn-outline-secondary ng-valid ng-touched ng-dirty" id="option3"
                      aria-pressed="false" routerLink="/user-activity-report">User Activity Report</button>
                  </div>
                </div>
               <ul id="transaction__reports" >
                <a href="#/order-report "><li>Order Report</li></a>
                <a href="#/customer-ledger"> <li>Customer Ledger  Report</li></a>
                  <a href="#/user-activity-report"><li>User Activity  Report</li></a>
              </ul>


              </form>
            </div>
          </div>

        </div>


      </div>
    </div>
  </div>
</div>