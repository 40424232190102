import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { UserService } from '../../services/user.service';
import { AlertService } from '../../utilities/alert';
import { ERROR_MESSAGES } from '../../utilities/constants';


@Component({
  selector: 'app-dealer-usermanagement',
  templateUrl: './dealer-usermanagement.component.html',
  styleUrls: ['./dealer-usermanagement.scss']
})
export class DealerUserManagement implements OnInit {
  isLoading = false;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: any = {};
  dealerUserList: any;
  errorMsg = ERROR_MESSAGES;
  constructor(private userService: UserService, private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.getDealerUser();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      lengthMenu: [10, 20, 50],
      processing: true
    };
  }

  getDealerUser(): void {
    this.isLoading = true;
    var postData = {
      pageNo: 0,
      pageSize: 0,
      userType: [
        "DEALER"
      ]
    }
    this.userService.getUsers(postData).subscribe(
      res => {
        this.isLoading = false;
        this.dealerUserList = res.userList;
        this.dtTrigger.next();
      },
      error => {
        this.isLoading = false;
        this.alertService.error(error.error.title);
      }
    );
  }

}
