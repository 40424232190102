import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject, ReplaySubject } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';

import { Users } from '../../utilities/models/user';
import { AlertService } from '../../utilities/alert/alert.service';
import { ERROR_MESSAGES } from '../../utilities/constants';
import { ReportService } from '../../services/report.service';
import { UserService } from '../../services/user.service';
@Component({
  selector: 'app-customer-ledger',
  templateUrl: './customer-ledger.component.html',
  styleUrls: ['./customer-ledger.component.scss']
})
export class CustomerLedgerComponent implements OnInit {

  customerLedgerForm: FormGroup;
  errorMsg = ERROR_MESSAGES;
  startDate: any = '';
  dealerUserList: any = [];
  endDate: any = '';
  dealer: any = '';
  isLoading = false;
  lob: any = '';
  maxDate: Date;
  canDownload = false;

  itemMultiFilterCtrl: FormControl = new FormControl();
  filteredItemsMulti: ReplaySubject<Array<Users>> = new ReplaySubject<Array<Users>>(1);
  private _onDestroy = new Subject<void>();

  constructor(private reportService: ReportService, private userService: UserService, private alertService: AlertService,
    private formBuilder: FormBuilder) {
    var date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth();
    const currentDate = date.getDate();
    this.maxDate = new Date(currentYear, currentMonth, currentDate);
  }

  ngOnInit(): void {
    this.buildForm();
    this.itemMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterItemsMulti();
      });
    this.filteredItemsMulti.next(this.dealerUserList.slice());
    this.getDealerUser();
  }

  buildForm(): void {
    this.customerLedgerForm = this.formBuilder.group({
      dealersList: ['', Validators.required],
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      lob: ['', [Validators.required]]
    });
  }

  trackByFn(): any {
    return null;
  }

  filterItemsMulti(): any {
    if (!this.dealerUserList) {
      return;
    }
    let search = this.itemMultiFilterCtrl.value;
    if (!search) {
      this.filteredItemsMulti.next(this.dealerUserList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredItemsMulti.next(
      this.dealerUserList.filter(item => item.emp_name.toLowerCase().indexOf(search) > -1)
    );
  }

  compareFn(industries, optionindustries): boolean {
    return industries && optionindustries ? industries.id === optionindustries.id :
      industries === optionindustries;
  }


  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getDealerUser(): void {
    var postData = {
      pageNo: 0,
      pageSize: 0,
      userType: [
        "DEALER"
      ]
    }
    this.userService.getUsers(postData).subscribe(
      res => {
        this.dealerUserList = res.userList;
        this.filteredItemsMulti.next(this.dealerUserList.slice());
      },
      error => {
        this.alertService.error(error.error.title);
      }
    );
  }

  onEndDateChanged(event) {
    this.endDate = event.value;
    if (this.endDate < this.startDate) {
      this.canDownload = true;
      this.alertService.error('To Date should be greater than From Date ');
      return;
    } else {
      this.canDownload = false;

    }
  }

  onStartDateChanged(event) {
    this.startDate = event.value;
    if (this.startDate > this.endDate) {
      this.canDownload = true;
      this.alertService.error('From Date should be less than To Date ');
      return;
    } else {
      this.canDownload = false;
    }
  }

  getCustomerReport() {
    var payload = {
      startDate: moment(this.startDate).format('YYYY-MM-DD'),
      endDate: moment(this.endDate).format('YYYY-MM-DD'),
      lob: this.customerLedgerForm.value.lob || '',
      dealer: this.customerLedgerForm.value.dealersList
    }
    this.isLoading = true;
    this.reportService.getCustomerReport(payload).subscribe(
      res => {
        this.isLoading = false;
        if (res.downloadUrl == null) {
          this.alertService.error(this.errorMsg.NO_RECORD_FOUND);
          return;
        } else {
          window.open(res.downloadUrl);
        }
      },
      error => {
        this.isLoading = false;
        this.alertService.error(error.error.title)
      }
    );
  }

}
