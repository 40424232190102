import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { UserService } from '../../services/user.service';
import { AlertService } from '../../utilities/alert';
import { ConfirmationModalComponent } from './../../Layout/confirmation-modal/confirmation-modal.component'
import { ERROR_MESSAGES } from '../../utilities/constants';
import { TopnavService } from '../../services/topnav.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  userId = '';
  errorMsg = ERROR_MESSAGES;
  profileDetails: any = {};
  imageFile: any;
  fileSize: any;
  isLoading = false;

  constructor(private userService: UserService, private alertService: AlertService,
    private modalService: NgbModal, private topnavService: TopnavService) { }

  ngOnInit(): void {
    this.userId = localStorage.getItem('userId');
    if (this.userId) {
      this.getProfile();
    };
  }

  getProfile(): void {
    this.isLoading = true;
    this.userService.getProfile(this.userId).subscribe(
      res => {
        this.isLoading = false;
        if (res.statusCode === 200) {
          this.profileDetails = res;
          this.profileDetails.imagePath = res.imagePath ? res.imagePath + '?' + new Date() : '';
          this.imageFile = res.imagePath ? res.imagePath + '?' + new Date() : '';
          this.topnavService.setDataInStorage('image', res.imagePath);
        }
      },
      error => {
        this.isLoading = false;
        this.alertService.error(error.error.title);
      }
    );
  }

  fileUpload(event): void {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.profileDetails.imagePath = event.target.result;
      this.profileDetails.imageData = event.target.result;      
    };
    reader.readAsDataURL(event.target.files[0]);
    this.fileSize = event.target.files[0].size / 1024;
    if (this.fileSize > 50) {
      this.alertService.error(this.errorMsg.IMAGE_SIZE);
      return;
    } else {
      setTimeout(() => {    
        this.saveProfile(this.profileDetails);
      }, 200);
    }
  }

  confirmModal() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      size: 'md',
      centered: true,
      keyboard: false
    };
    const modalRef = this.modalService.open(ConfirmationModalComponent, ngbModalOptions);
    const contentComponentInstance = modalRef.componentInstance;
    contentComponentInstance.subject = 'REMOVE IMAGE';
    contentComponentInstance.message =
      'Are you sure you want to remove profile image?';
    contentComponentInstance.action = 'removeImage';
    modalRef.result.then((result) => {
      if (result) {
        this.removeImage(this.profileDetails);
      } else {
      }
    });
  }

  saveProfile(data): void {
    this.isLoading = true;
    this.userService.saveProfile(data).subscribe(
      res => {
        this.isLoading = false;
        if (res.statusCode === 200) {
          this.topnavService.setDataInStorage('image', res.imagePath + '?' + new Date());
          this.alertService.success(this.errorMsg.PROFILE_SAVED);
          setTimeout(() => {
            location.reload();
          }, 1000);
        }
      },
      error => {
        this.isLoading = false;
        this.alertService.error(error.error.title);
      }
    );
  }

  removeImage(data) {
    this.isLoading = true;
    data.imageData = data.imagePath = '';
    this.userService.saveProfile(data).subscribe(
      res => {
        this.isLoading = false;
        if (res.statusCode === 200) {
          this.topnavService.setDataInStorage('image', res.imagePath);
          this.alertService.success(this.errorMsg.PROFILE_SAVED);
          setTimeout(() => {
            location.reload();
          }, 1000);
        }
      },
      error => {
        this.isLoading = false;
        this.alertService.error(error.error.title);
      }
    );
  }

}
