<div id="wrapper">
    <div *ngIf="isLoading" id="mydiv">
        <img src="../../../assets/images/Spinner.gif" class="ajax-loader" />
    </div>
    <app-sidebar></app-sidebar>

    <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
            <app-topnav></app-topnav>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a>My Orders</a></li>
                <li class="breadcrumb-item"><a id="pendingapr">Pending Approvals</a></li>
                <li class="breadcrumb-menu d-md-down-none"></li>
            </ol>
            <div class="card-header">Pending Approvals</div>
            <div class="container-fluid">
                <form class="bgWhite">
                    <div class="form-group form-inline form-io">

                        <div id="DataTables_Table_0_length" class="dataTables_length"><label>Show
                                <select (change)="onSizeChange(pageSize)" [(ngModel)]="pageSize"
                                    name="DataTables_Table_0_length" aria-controls="DataTables_Table_0"
                                    class="show_select">
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                </select> entries</label>
                        </div>
                        <div id="DataTables_Table_0_filter" class="dataTables_filter"><label>Search:
                                <input type="search" [(ngModel)]="searchText" name="searchText"
                                    (input)="searchQuery($event)" class="data-filter-search" placeholder=""
                                    aria-controls="DataTables_Table_0"></label>
                        </div>


                    </div>

                    <div>
                        <table class="table table-striped tableBorder">
                            <thead>
                                <tr>
                                    <th>
                                        <div class="form-check width50">
                                            <input type="checkbox" name="selectAll" [(ngModel)]="selectAll"
                                                id="defaultCheck1" class="form-check-input cursor"
                                                (ngModelChange)="toggleAllSelection(selectAll)">
                                            <label for="defaultCheck1" class="form-check-label"> Select All </label>
                                        </div>
                                    </th>
                                    <th [ngClass]="{'ascSort': reverseNumber == true, 'descSort':reverseNumber == false,'bothSort':reverseNumber===''}"
                                        (click)="onSort('order_number')">Order Id</th>
                                    <th [ngClass]="{'ascSort': reverseQty == true, 'descSort':reverseQty == false,'bothSort':reverseQty===''}"
                                        (click)="onSort('total_Qty')">Total Quantity</th>
                                    <th [ngClass]="{'ascSort': reverseAmount == true, 'descSort':reverseAmount == false,'bothSort':reverseAmount===''}"
                                        (click)="onSort('total_Amount')">Total Amount</th>
                                    <th [ngClass]="{'ascSort': dealerCode == true, 'descSort':dealerCode == false,'bothSort':dealerCode===''}"
                                        (click)="onSort('dealer_code')">Dealer Code</th>
                                    <th [ngClass]="{'ascSort': dealerName == true, 'descSort':dealerName == false,'bothSort':dealerName===''}"
                                        (click)="onSort('dealer_name')">Dealer Name</th>
                                    <th [ngClass]="{'ascSort': tsm == true, 'descSort':tsm == false,'bothSort':tsm===''}"
                                        (click)="onSort('TSM')">TSM Name</th>
                                    <th [ngClass]="{'ascSort': territory == true, 'descSort':territory == false,'bothSort':territory===''}"
                                        (click)="onSort('Territory')">Territory</th>
                                    <th [ngClass]="{'ascSort': orderDate == true, 'descSort':orderDate == false,'bothSort':orderDate===''}"
                                        (click)="onSort('order_date')">Order Date</th>
                                    <th [ngClass]="{'ascSort': orderStatus == true, 'descSort':orderStatus == false,'bothSort':orderStatus===''}"
                                        (click)="onSort('OrderStatus')">Status</th>
                                    <th>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let pendingOrder of pendingOrderList;let i = index">
                                    <th scope="row">
                                        <div class="form-check">
                                            <input type="checkbox" id="{{i + 1}}" name="selectOne"
                                                [(ngModel)]="pendingOrder.selected"
                                                (ngModelChange)=ordersSelected(pendingOrder.selected,i)
                                                class="form-check-input curosr"></div>
                                    </th>
                                    <td>{{pendingOrder?.order_number}}</td>
                                    <td>{{pendingOrder?.total_Qty}}</td>
                                    <td>{{pendingOrder?.total_Amount}}</td>
                                    <td>{{pendingOrder?.dealer_code}}</td>
                                    <td>{{pendingOrder?.dealer_name}}</td>
                                    <td>{{pendingOrder?.tsm}}</td>
                                    <td>{{pendingOrder?.territory}}</td>
                                    <td>{{pendingOrder?.order_date | date: 'dd/MMM/yyyy'}}</td>
                                    <td>{{pendingOrder?.orderStatus}}</td>
                                    <td (click)="openDetailsModal(pendingOrder)" class="cursor">
                                        <i class="fa fa-eye eye-icon" aria-hidden="true"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <p class="text-center" *ngIf="pendingOrderList.length===0">{{errorMsg.NO_RECORD_FOUND}}</p>
                    <div *ngIf="pendingOrderList.length" class="row">
                        <div class="col-md-3">
                            <div>
                                <button (click)="openAcceptModal()" class="btn btn-primary pd-upload">Accept</button>
                                <button (click)="openRejectModal()" class="btn btn-primary pd-upload">Reject</button>
                            </div>
                        </div>

                        <div class="col-md-9">
                            <app-pagination class="float-right" [total]="page" [size]="pageSize"
                                (onChanged)="pageChanged($event)">
                            </app-pagination>
                        </div>
                    </div>
                </form>
            </div>
            <a class='scroll-to-top rounded' href='#page-top'>
                <i class='fas fa-angle-up'></i>
            </a>