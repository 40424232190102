import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UserService } from '../../services/user.service';
import { COMMONFORMVALIDATIONRULE } from '../../utilities/formValidation';
import { AlertService } from '../../utilities/alert';
import { ERROR_MESSAGES } from '../../utilities/constants';

@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.scss']
})
export class AddUserComponent implements OnInit {
  userForm: FormGroup;
  titleValue = 'Add';
  userId: any;
  errorMsg = ERROR_MESSAGES;
  obj = Object.getOwnPropertyNames;
  userDetails: any = {
    roleId: ''
  };
  userCode: any;
  saveInProgress = false;
  isLoading = false;

  constructor(private alertService: AlertService, private router: Router, private route: ActivatedRoute,
    private userService: UserService, private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.userId = localStorage.getItem('userId');
    this.buildForm();
    this.userCode = this.route.snapshot.params.userCode;
    if (this.userCode) {
      this.getUserById()
    }
  }

  /**
 * @method buildForm()
 * @desc used to build form.
 */
  buildForm(): void {
    this.userForm = this.formBuilder.group({
      userName: [this.userDetails.userName, [Validators.required, Validators.maxLength(50), COMMONFORMVALIDATIONRULE.userName]],
      email: [this.userDetails.email, [Validators.required, COMMONFORMVALIDATIONRULE.emailPattern, Validators.maxLength(64)]],
      mobileNo: [this.userDetails.mobileNo, [Validators.required, COMMONFORMVALIDATIONRULE.mobileNo, Validators.maxLength(14)]],
      userRole: [this.userDetails.status, [Validators.required]]
    });
  }

  getUserById(): void {
    this.isLoading = true;
    this.titleValue = 'Edit';
    var postData = {
      pageNo: 0,
      pageSize: 0,
      userType: [],
      user_Code: this.userCode
    }
    this.userService.getUserById(postData).subscribe(
      res => {
        this.isLoading = false;
        this.userDetails = res.userList[0];
        this.userForm.patchValue({
          userName: this.userDetails.emp_name,
          email: this.userDetails.emp_email,
          mobileNo: this.userDetails.emp_phone,
          userRole: this.userDetails.roleid
        });
      },
      error => {
        this.isLoading = false;
        this.alertService.error(error.error.title)
      }
    );
  }

  onStatusChange(event) {
    this.userDetails.status = event.target.checked;
  }

  saveUser(data) {
    this.isLoading = true;
    this.saveInProgress = true;
    var payload = {
      user_Code: data.emp_code || 0,
      roleId: this.userForm.value.userRole || data.role,
      name: this.userForm.value.userName || data.emp_name,
      email: this.userForm.value.email || data.emp_email,
      phone: this.userForm.value.mobileNo || data.emp_phone,
      isActive: this.userDetails.status || data.status || false,
      joining_Date: data.joining_date || new Date(),
      territoryCode: [],
      createdBy: this.userId,
    }
    
    this.userService.saveUser(payload).subscribe(
      res => {
        this.isLoading = false;
        this.saveInProgress = false;
        if (res.statusCode == 200) {
          this.router.navigate(['/other-user']);
          if (this.userCode) {
            this.alertService.success(this.errorMsg.USER_SAVED);
          } else {
            this.alertService.success('New user is successfully created with code ' + res.user_Code);
          }
        }
      },
      error => {
        this.isLoading = false;
        this.saveInProgress = false;
        this.alertService.error(error.error.title)
      }
    );
  }
}