import { Component, OnInit } from '@angular/core';

import { TopnavService } from '../../../app/services/topnav.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  navbarOpen: boolean;
  roleId: any;
  constructor(public topser: TopnavService) { }

  ngOnInit(): void {
    this.roleId = this.topser.getDataFromStorage('role');
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  hideSideNav() {
    document.getElementById("accordionSidebar").style.display = "none";
    
  }
}

