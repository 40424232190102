import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AlertService } from './../utilities/alert';
import { OAuthSettings } from '../../environments/environment';
import { UserService } from './user.service';
import { TopnavService } from './topnav.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  public authenticated: boolean;

  constructor(private router: Router, private msalService: MsalService, private alertService: AlertService,
    private userService: UserService, private topnavService: TopnavService) {
    this.authenticated = this.msalService.getAccount() != null;
  }

  async signIn(email): Promise<void> {
    OAuthSettings.loginHint = email;
    this.topnavService.setDataInStorage('emailId', email);
    const result = await this.msalService.loginRedirect(OAuthSettings);
    this.authenticated = false;
    return result;
  }

  getUserDetails(): void {
    this.getAccessToken();
    let email = this.topnavService.getDataFromStorage('emailId');
    this.userService.userDetails(email).subscribe(
      res => {
        if (res.statusCode === 200) {
          this.topnavService.setDataInStorage('role', res.userDetails.roleId);
          localStorage.setItem('userId', res.userDetails.userId);
          localStorage.setItem('name', res.userDetails.name);
          this.topnavService.setDataInStorage('image', res.userDetails.imagePath);
          this.topnavService.setDataInStorage('accessCode', res.accessToken);
          this.router.navigate(['/dashboard']);
          this.alertService.success('User logged in successfully');
        }
      },
      error => {
        this.alertService.error(error.error.title);
      }
    );
  }

  azureSignOut(): void {
    this.msalService.logout();
  }

  logout() {
    this.authenticated = false;
    this.router.navigate(['/']);
    this.alertService.success('User logout successfully')
    localStorage.clear();
    sessionStorage.clear();
    // this.azureSignOut();
  }

  async getAccessToken(): Promise<string> {
    const result = await this.msalService.acquireTokenSilent(OAuthSettings)
      .catch((reason) => {
        this.alertService.error('Get token failed', JSON.stringify(reason, null, 2));
      });

    if (result) {
      return result.accessToken;
    }
    this.authenticated = false;
    return null;
  }
}
