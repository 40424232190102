import { Component, OnInit } from '@angular/core';

import { AlertService } from '../../utilities/alert/alert.service';
import { ERROR_MESSAGES } from '../../utilities/constants';
import { ReportService } from '../../services/report.service';

@Component({
  selector: 'app-product-master-report',
  templateUrl: './product-master-report.component.html',
  styleUrls: ['./product-master-report.component.scss']
})
export class ProductMasterReportComponent implements OnInit {
  errorMsg = ERROR_MESSAGES;
  lob: any = '';
  isLoading = false;

  constructor(private reportService: ReportService, private alertService: AlertService) { }

  ngOnInit(): void {
  }

  onLOBChange(lob) {
    this.lob = lob;
  }

  getProductMasterReport() {
    var payload = {
      lob: this.lob
    }
    if (this.lob === '') {
      this.alertService.error('Please select LOB value');
      return;
    }
    this.isLoading = true;
    this.reportService.getProductMasterReport(payload).subscribe(
      res => {
        this.isLoading = false;
        if (res.downloadUrl == null) {
          this.alertService.error(this.errorMsg.NO_RECORD_FOUND);
          return;
        } else {
          window.open(res.downloadUrl);
        }
      },
      error => {
        this.isLoading = false;
        this.alertService.error(error.error.title)
      }
    );
  }

}
