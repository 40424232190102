import { Component, OnInit } from '@angular/core';

import { AlertService } from '../../utilities/alert/alert.service';
import { ERROR_MESSAGES } from '../../utilities/constants';
import { ReportService } from '../../services/report.service';
@Component({
  selector: 'app-user-master-report',
  templateUrl: './user-master-report.component.html',
  styleUrls: ['./user-master-report.component.scss']
})
export class UserMasterReportComponent implements OnInit {
  errorMsg = ERROR_MESSAGES;
  roleId: any = 0;
  lob: any = '';
  isLoading = false;

  constructor(private reportService: ReportService, private alertService: AlertService) { }

  ngOnInit(): void {
  }


  onRoleChange(role) {
    this.roleId = role;
  }

  onLOBChange(lob) {
    this.lob = lob;
  }

  getUserMasterReport() {
    var payload = {
      roleId: this.roleId,
      lob: this.lob
    }
    this.isLoading = true;
    this.reportService.getUserMasterReport(payload).subscribe(
      res => {
        this.isLoading = false;
        if (res.downloadUrl == null) {
          this.alertService.error(this.errorMsg.NO_RECORD_FOUND);
          return;
        } else {
          window.open(res.downloadUrl);
        }
      },
      error => {
        this.isLoading = false;
        this.alertService.error(error.error.title)
      }
    );
  }

}
