<div id="wrapper">
  <app-sidebar></app-sidebar>

  <div *ngIf="isLoading" id="mydiv">
    <img src="../../../assets/images/Spinner.gif" class="ajax-loader" />
  </div>

  <div id="content-wrapper" class="d-flex flex-column">
    <div id="content">
      <app-topnav></app-topnav>
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/reject-reasons">Reject Reasons</a></li>
        <li class="breadcrumb-item"><a id="add__reasons">{{titleValue}}</a></li>
        <li class="breadcrumb-menu d-md-down-none"></li>
      </ol>
      <div class="card-header">{{titleValue}}</div>
      <div class="container-fluid">
        <form class="bgWhite" [formGroup]="reasonForm" (ngSubmit)="saveRejectReasons(reasonDetails)">
          <div class="row">
            <div class="col-md-12">
              <label for="">Description<span id="asterisk_red">*</span></label>

              <textarea rows="6" #reasonDescription formControlName="reasonDescription" name="reasonDescription"
                id="productDesc" placeholder="Enter text here..." title="{{errorMsg.MAX_LENGTH250}}"
                maxlength="250"></textarea>
              <div class="has-error"
                *ngIf="reasonForm.controls['reasonDescription'].errors && !reasonForm.controls['reasonDescription'].pristine">
                <div [ngSwitch]="obj(reasonForm.controls['reasonDescription'].errors)[0]">
                  <div *ngSwitchCase="'required'" class="help-block">
                    {{errorMsg.REQUIRED}}</div>
                  <div *ngSwitchCase="'maxlength'" class="help-block">
                    {{errorMsg.MAX_LENGTH100}}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group text-right">
            <button [disabled]="reasonForm.invalid || saveInProgress" type="submit"
              class="btn btn-primary pd-btn-save">Save</button>
          </div>
        </form>
      </div>