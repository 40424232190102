<div id="wrapper">
  <app-sidebar></app-sidebar>
  <div *ngIf="isLoading" id="mydiv">
    <img src="../../../assets/images/Spinner.gif" class="ajax-loader" />
  </div>
  <div id="content-wrapper" class="d-flex flex-column">
    <div id="content">
      <app-topnav></app-topnav>
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/reports">Reports</a></li>
        <li class="breadcrumb-item"> <a id="reports_bcum_col">Transactions</a> </li>
        <li class="breadcrumb-item"><a id="pendingapr">Order Report</a></li>
      </ol>
      <div class="card-header">Order Report</div>
      <div class="container-fluid">
        <form [formGroup]="orderReportForm" class="bgWhite">
          <div class="order-report-container">
            <div class="form-group row">
              <div class="col-md-12">
                <label class="col-form-label from_date" for="text-input">From Date<span id="asterisk_red">*</span><span id="colo__ad">:</span>
                </label>
                <mat-form-field color="primary">
                  <mat-label>Choose from date</mat-label>
                  <input matInput #startDate formControlName="startDate" [max]="maxDate" [matDatepicker]="pickerFrom"
                    (dateChange)="onStartDateChanged($event)" disabled>
                  <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                  <mat-datepicker #pickerFrom disabled="false"></mat-datepicker>
                </mat-form-field>
                <div class="has-error"
                  *ngIf="orderReportForm.controls['startDate'].errors && !orderReportForm.controls['startDate'].pristine">
                  <div [ngSwitch]="obj(orderReportForm.controls['startDate'].errors)[0]">
                    <div *ngSwitchCase="'required'" class="help-block">
                      {{errorMsg.REQUIRED}}</div>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <label class="col-form-label to_date" for="text-input">To Date<span id="asterisk_red">*</span><span
                    id="vis__hid"></span><span id="colo__ad">:</span> </label>
                <mat-form-field color="primary">
                  <mat-label>Choose to date</mat-label>
                  <input matInput #endDate formControlName="endDate" [max]="maxDate" [matDatepicker]="pickerTo"
                    (dateChange)="onEndDateChanged($event)" disabled>
                  <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                  <mat-datepicker #pickerTo disabled="false"></mat-datepicker>
                </mat-form-field>
                <div class="has-error"
                  *ngIf="orderReportForm.controls['endDate'].errors && !orderReportForm.controls['endDate'].pristine">
                  <div [ngSwitch]="obj(orderReportForm.controls['endDate'].errors)[0]">
                    <div *ngSwitchCase="'required'" class="help-block">
                      {{errorMsg.REQUIRED}}</div>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <label class="col-form-label" for="text-input">Select LOB<span id="vis__hidtwo"></span>: </label>
                <select name="lob" (change)="onLOBChange(lob)" [(ngModel)]="lob" [ngModelOptions]="{standalone: true}"
                  class="ml-1 show_select bgWhite">
                  <option selected value="">All</option>
                  <option value="seed">Seed</option>
                  <option value="cp">CP</option>
                </select>
              </div>
            </div>
          </div>
          <button type=" button" color="primary" [disabled]="orderReportForm.invalid || canDownload" rounded="true"
            (click)="getOrderReport()" class="btn btn-primary save-btn">Download</button>
        </form>
      </div>
    </div>
  </div>