import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { TopnavService } from './topnav.service';
import { EncrDecrService } from './../services/encrDecr.service';

import { ApiBaseObject } from './../utilities/models/api-base-object.model';
import { ApiService } from './../services/api.service';
import { API_ENDPOINTS } from './../utilities/api-endpoints-constants';

@Injectable()

export class UserService {
    lat: any = '';
    lng: any = '';
    ipAddress: any = '';

    private apiObject: ApiBaseObject;
    constructor(private apiService: ApiService, private EncrDecr: EncrDecrService,
        private topNavService: TopnavService) {
        this.getIP();
        this.getPosition();
    }

    /**
     * @method loginUser()
     * @desc used to sign-in user into Corteva.
     */
    loginUser(email: any): any {
        email = encodeURIComponent(email);
        this.apiObject = this.apiService.setApiCallObject(API_ENDPOINTS.LOGIN + email, {}, false);
        return this.apiService.getApi(this.apiObject).pipe(
            map((response) => {
                return response;
            },
                (error) => {
                    return error;
                }
            ));
    }

    getIP() {
        this.apiService.getIPAddress().subscribe((res: any) => {
            this.ipAddress = res.ip;
        });
    }

    getPosition(): Promise<any> {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resp => {
                resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
                this.lat = resp.coords.latitude;
                this.lng = resp.coords.longitude;
            },
                err => {
                    reject(err);
                });
        });
    }

    userDetails(email: any): any {
        this.apiObject = this.apiService.setApiCallObject(API_ENDPOINTS.USER_DETAILS + email
            + '?latitude=' + this.lat + '&longitude=' + this.lng + '&ipaddress=' + this.ipAddress + '&plateform=web'
            + '&accesscode=' + this.EncrDecr.set(), {}, false);
        return this.apiService.getApi(this.apiObject).pipe(
            map((response) => {
                return response;
            },
                (error) => {
                    return error;
                }
            ));
    }

    getProfile(id: any): any {
        this.apiObject = this.apiService.setApiCallObject(API_ENDPOINTS.GET_PROFILE + id, {},
            true);
        return this.apiService.getApi(this.apiObject).pipe(
            map((response) => {
                return response;
            },
                (error) => {
                    return error;
                }
            ));
    }

    saveProfile(postData): any {
        this.apiObject = this.apiService.setApiCallObject(API_ENDPOINTS.SAVE_PROFILE, postData,
            true);
        return this.apiService.postApi(this.apiObject).pipe(
            map((response) => {
                return response;
            },
                (error) => {
                    return error;
                }
            ));
    }

    getUsers(postData): any {
        this.apiObject = this.apiService.setApiCallObject(API_ENDPOINTS.GET_USERS, postData,
            true);
        return this.apiService.postApi(this.apiObject).pipe(
            map((response) => {
                return response;
            },
                (error) => {
                    return error;
                }
            ));
    }

    getUserById(data): any {
        this.apiObject = this.apiService.setApiCallObject(API_ENDPOINTS.GET_USERS, data, true);
        return this.apiService.postApi(this.apiObject).pipe(
            map((response) => {
                return response;
            },
                (error) => {
                    return error;
                }
            ));
    }

    saveUser(postData): any {
        this.apiObject = this.apiService.setApiCallObject(API_ENDPOINTS.SAVE_USER, postData,
            true);
        return this.apiService.postApi(this.apiObject).pipe(
            map((response) => {
                return response;
            },
                (error) => {
                    return error;
                }
            ));
    }

}
