import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { Router } from '@angular/router';
import { AlertService } from './../utilities/alert';
import { environment } from '../../environments/environment';
import { ApiBaseObject } from './../utilities/models/api-base-object.model';
import { TopnavService } from './topnav.service';
@Injectable()
/**
 * @desc this class is used to get, post, update api data.
 */
export class ApiService {

    apiUrl = environment.url;
    constructor(private http: HttpClient, private topnavService: TopnavService,
        private router: Router, private alertService: AlertService) {

    }
    /**
     * @method getApi()
     * @desc getApi is common method for get API
     * @param endpoint :string - end point url of api calling.
     * @param tokenRequiredFlag :boolean - define token is mandatory or not.
     * @param returnWithoutMap :boolean - a boolean to return with or without map.
     */
    getApi(apiObject: ApiBaseObject): any {
        let httpOptions = this.getHeaders(apiObject.tokenRequiredFlag);
        const request = this.http.get(`${this.apiUrl}/${apiObject.endpoint}`, { headers: httpOptions });
        return request.pipe(
            map((res: Response) => {
                return res;
            }),
            catchError(err => {
                if (err.status === 401) {
                    this.router.navigate(['/login']);
                    this.alertService.error('Invalid token. Please try again.')
                    localStorage.clear();
                    sessionStorage.clear();
                }
                return err;
            })
        );
    }

    /**
     * @method postApi()
     * @desc postApi is common method for post API
     * @param endpoint :string - end point url of api calling.
     * @param data :json - request payload of api.
     * @param tokenRequiredFlag :boolean - define token is mandatory or not.
     */
    postApi(apiObject: ApiBaseObject): any {
        let httpOptions = this.getHeaders(apiObject.tokenRequiredFlag);
        const request = this.http.post(`${this.apiUrl}/${apiObject.endpoint}`,
            apiObject.apiData, { headers: httpOptions });
        return request.pipe(
            map((res: Response) => {
                return res;
            }),
            catchError(err => {
                if (err.status === 401) {
                    this.router.navigate(['/login']);
                    this.alertService.error('Invalid token. Please try again.')
                    localStorage.clear();
                    sessionStorage.clear();
                }
                return err;
            })
        )
    }

    private getHeaders(tokenRequiredFlag?: boolean): any {
        let headers;
        if (tokenRequiredFlag) {
            headers = new HttpHeaders({
                'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
                'Pragma': 'no-cache',
                'Expires': '0',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.topnavService.getDataFromStorage('accessCode')
            });
        } else {
            headers = new HttpHeaders({
                'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
                'Pragma': 'no-cache',
                'Expires': '0',
                'Content-Type': 'application/json',
            });
        }
        return headers;
    }


    /**
     * @method setApiCallObject()
     * @desc method to set api base object data.
     * @param endpoint api endpoint url.
     * @param apiData api data to post.
     * @param tokenRequiredFlag api token required for the api call.
     * @param alertHide alert hide funcationality.
     */
    setApiCallObject(endpoint, apiData, tokenRequiredFlag): ApiBaseObject {
        return {
            endpoint,
            apiData,
            tokenRequiredFlag
        };
    }

    public getIPAddress() {
        return this.http.get("https://api.ipify.org/?format=json");
    }
}
