<div id="wrapper">
    <app-sidebar></app-sidebar>
    <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
            <app-topnav></app-topnav>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a id="pendingapr">Notification Management</a></li>
            </ol>
            <div class="card-header">Notification List</div>
            <div class="container-fluid">
                <form>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Mode</th>
                                <th>To</th>
                                <th>Trigger Point</th>
                                <th>Body</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> djxw</td>
                                <td> dw</td>
                                <td> dw</td>
                                <td> dww</td>
                                <td routerLink="/edit-notifications">
                                    <i class="fas fa-edit"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>


                </form>

            </div>

        </div>