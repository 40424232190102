// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: 'https://apiuat.dealerconnect.corteva.pk/api/v1.0',
};

export const OAuthSettings = {
  appId: 'a9c18bed-7311-4d3a-a0f2-6fff061e75da',
  authority: 'https://login.microsoftonline.com/agcompany.onmicrosoft.com',
  redirectUri: "https://dealerconnectuat.corteva.pk/",
  loginHint: '',
  scopes: [
    'user.read'
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
