<div id="wrapper">
    <app-sidebar></app-sidebar>

    <div *ngIf="isLoading" id="mydiv">
        <img  src="../../../assets/images/Spinner.gif" class="ajax-loader" />
    </div>

    <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
            <app-topnav></app-topnav>
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a>User Management</a>
                </li>
                <li class="breadcrumb-item">
                    <a id="ax__user">AX User</a>
                </li>
                <li class="breadcrumb-menu d-md-down-none"></li>
            </ol>
            <div class="card-header">AX User</div>
            <div class="container-fluid">
                <form class="form-group form-inline">
                    <table [dtTrigger]="dtTrigger" class="table table-striped table-striped-in" datatable
                        [dtOptions]="dtOptions">
                        <thead>
                            <tr>
                                <th>Code</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Mobile Number</th>
                                <th>Status</th>
                                <th>Territory Assigned</th>
                                <th>Role</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let axUser of axUserList">
                                <td>{{axUser?.emp_code}}</td>
                                <td>{{axUser?.emp_name}}</td>
                                <td class="wordBreak">{{axUser?.emp_email}}</td>
                                <td>{{axUser?.emp_phone}}</td>
                                <td>{{axUser?.status}}</td>
                                <td>{{axUser?.territory_assigned}}</td>
                                <td>{{axUser?.role}}</td>
                            </tr>
                        </tbody>
                    </table>

                </form>
            </div>
        </div>
    </div>
</div>