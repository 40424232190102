import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';

import { OrderDetailsComponent } from 'src/app/Layout/order-details/order-details.component';
import { OrderService } from '../../services/order.service';
import { AlertService } from '../../utilities/alert/alert.service';
import { TopnavService } from 'src/app/services/topnav.service';
import { OrderStatusModalComponent } from 'src/app/Layout/order-status-modal/order-status-modal.component';
import { STATUS } from '../../utilities/constants';
import { ERROR_MESSAGES } from '../../utilities/constants';
@Component({
  selector: 'app-pending-orders',
  templateUrl: './pending-orders.component.html',
  styleUrls: ['./pending-orders.component.scss']
})
export class PendingOrdersComponent implements OnInit {
  orderStatusValue = STATUS;
  isLoading = false;
  errorMsg = ERROR_MESSAGES;
  selectAll = false;
  selectOne = false;
  objectData: any = {
    allOrder: false
  };
  selectedValues: any = [];
  userId: any;
  pendingOrderList: any = [];
  pendingList: any = [];
  searchText: string;
  sortColumn: any = 'order_date';
  isAscending: any = false;
  page: any;
  currentPage: any = 1;
  pageSize = 10;
  modalObject: Object;
  closeResult: string;
  reverseNumber: any = '';
  reverseQty: any = '';
  reverseAmount: any = '';
  dealerCode: any = '';
  dealerName: any = '';
  tsm: any = '';
  territory: any = '';
  orderDate: any = '';
  orderStatus: any = '';
  acceptResult: any;
  rejectResult: any;
  existApi: any;
  fromDate: any = '';
  toDate: any = '';

  constructor(private route: ActivatedRoute, private topNav: TopnavService, private modalService: NgbModal, private orderService: OrderService, private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.fromDate = this.route.snapshot.params.fromDate;
    this.toDate = this.route.snapshot.params.toDate;
    this.userId = this.topNav.getDataFromStorage('userId');
    if (this.userId) {
      this.getPendingOrders();
    }
  }

  onSort(key): void {
    this.sortColumn = key;
    if (this.isAscending == true) {
      this.isAscending = false
    } else {
      this.isAscending = !this.isAscending;
    }
    this.pendingOrderList = [];
    this.getPendingOrders();
    switch (key) {
      case 'order_number':
        this.reverseNumber = this.isAscending;
        this.reverseQty = ''
        this.reverseAmount = '';
        this.dealerCode = '';
        this.dealerName = '';
        this.tsm = '';
        this.territory = '';
        this.orderDate = '';
        this.orderStatus = '';
        break;
      case 'total_Qty':
        this.reverseQty = this.isAscending;
        this.reverseNumber = '';
        this.dealerCode = '';
        this.reverseAmount = '';
        this.dealerName = '';
        this.tsm = '';
        this.territory = '';
        this.orderDate = '';
        this.orderStatus = '';
        break;
      case 'total_Amount':
        this.reverseAmount = this.isAscending;
        this.reverseQty = ''
        this.reverseNumber = '';
        this.dealerCode = '';
        this.dealerName = '';
        this.tsm = '';
        this.territory = '';
        this.orderDate = '';
        this.orderStatus = '';
        break;
      case 'dealer_code':
        this.reverseNumber = '';
        this.reverseQty = '';
        this.reverseAmount = '';
        this.dealerCode = this.isAscending;
        this.dealerName = '';
        this.tsm = '';
        this.territory = '';
        this.orderDate = '';
        this.orderStatus = '';
        break;
      case 'dealer_name':
        this.dealerName = this.isAscending;
        this.reverseNumber = '';
        this.reverseQty = '';
        this.reverseAmount = '';
        this.dealerCode = '';
        this.tsm = '';
        this.territory = '';
        this.orderDate = '';
        this.orderStatus = '';
        break;
      case 'TSM':
        this.tsm = this.isAscending;
        this.reverseNumber = '';
        this.reverseQty = '';
        this.reverseAmount = '';
        this.dealerCode = '';
        this.dealerName = '';
        this.territory = '';
        this.orderDate = '';
        this.orderStatus = '';
        break;
      case 'Territory':
        this.territory = this.isAscending;
        this.reverseNumber = '';
        this.reverseQty = '';
        this.reverseAmount = '';
        this.dealerCode = '';
        this.dealerName = '';
        this.tsm = '';
        this.orderDate = '';
        this.orderStatus = '';
        break;
      case 'order_date':
        this.orderDate = this.isAscending;
        this.reverseNumber = '';
        this.reverseQty = '';
        this.reverseAmount = '';
        this.dealerCode = '';
        this.dealerName = '';
        this.tsm = '';
        this.territory = '';
        this.orderStatus = '';
        break;
      case 'OrderStatus':
        this.orderStatus = this.isAscending;
        this.reverseNumber = '';
        this.reverseQty = '';
        this.reverseAmount = '';
        this.dealerCode = '';
        this.dealerName = '';
        this.tsm = '';
        this.territory = '';
        this.orderDate = '';
        break;
      default:
    }
  }

  onSizeChange(size): void {
    this.pageSize = size;
    this.currentPage = 1;
    this.pendingOrderList = [];
    this.getPendingOrders();
  }

  searchQuery(event): void {
    this.searchText = event.target.value;
    this.pendingOrderList = [];
    this.getPendingOrders();
  }

  pageChanged(e: number) {
    if (this.currentPage != e) {
      this.currentPage = e;
      this.getPendingOrders();
    }
    this.currentPage = e;
  }

  ordersSelected(status, index) {
    if (status) {
      this.pendingOrderList[index].selected = status;
    }
    this.selectedValues = [];
    this.pendingOrderList.forEach(element => {
      if (element.selected == true) {
        this.selectedValues.push(element.order_number.toString());
      }
    });
    if (this.selectedValues.length === this.pendingOrderList.length) {
      this.selectAll = true;
    } else {
      this.selectAll = false;
    }
  }

  toggleAllSelection(isActive) {
    if (isActive) {
      this.selectedValues = [];
      this.pendingOrderList.forEach(element => {
        element.selected = isActive;
        if (element.selected == true) {
          this.selectedValues.push(element.order_number.toString());
        }
      });
    } else {
      this.selectedValues = [];
      this.pendingOrderList.forEach(item => {
        item.selected = ''
        if (item.selected == true) {
          this.selectedValues.push(item.order_number);
        }
      })
    }
  }

  getPendingOrders(): void {
    this.isLoading = true;
    var payload = {
      userCode: this.userId,
      searchText: this.searchText || '',
      sortColumn: this.sortColumn || '',
      isAscending: this.isAscending,
      pageNo: this.currentPage,
      pageSize: this.pageSize,
      fromDate: this.fromDate || '',
      toDate: this.toDate || ''
    }

    if (this.existApi) {
      this.existApi.unsubscribe();
    }
    this.existApi = this.orderService.getPendingOrders(payload).subscribe(
      res => {
        this.isLoading = false;
        this.page = res.totalRecords;
        this.pendingOrderList = res.orders;
      },
      error => {
        this.isLoading = false;
        this.alertService.error(error.error.title)
      }
    );
  }

  openDetailsModal(content) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      size: 'lg',
      centered: true,
      keyboard: false
    };
    const modalRef = this.modalService.open(OrderDetailsComponent, ngbModalOptions);
    const contentComponentInstance = modalRef.componentInstance;
    contentComponentInstance.modalObject = content;
    contentComponentInstance.action = 'pending-orders';
    modalRef.result.then((result) => {
      if (result) {
        this.selectedValues = [];
        if (result.action === 'accept') {
          this.selectedValues.push(result.orderId.toString());
          this.openAcceptModal();
        }
        if (result.action === 'reject') {
          this.selectedValues.push(result.orderId.toString());
          this.openRejectModal();
        }
      }
    });
  }

  openAcceptModal() {
    if (this.selectedValues.length) {
      const ngbModalOptions: NgbModalOptions = {
        backdrop: 'static',
        size: 'md',
        centered: true,
        keyboard: false
      };
      const modalRef = this.modalService.open(OrderStatusModalComponent, ngbModalOptions);
      const contentComponentInstance = modalRef.componentInstance;
      contentComponentInstance.subject = 'ACCEPT ORDER';
      contentComponentInstance.message =
        'Are you sure you want to accept ' + this.selectedValues.length + ' order(s)?';
      contentComponentInstance.action = 'acceptOrder';
      modalRef.result.then((result) => {
        if (result) {
          this.acceptResult = {
            orderno: this.selectedValues,
            statusId: this.orderStatusValue.orderAccepted,
            comment: result.comment,
            createdBy: this.userId
          }
          this.approveOrders(this.acceptResult, 'accept');
        } else {
        }
      });
    } else {
      this.alertService.error(this.errorMsg.SELECT_ORDERS)
    }
  }


  openRejectModal() {
    if (this.selectedValues.length) {
      const ngbModalOptions: NgbModalOptions = {
        backdrop: 'static',
        size: 'md',
        centered: true,
        keyboard: false
      };
      const modalRef = this.modalService.open(OrderStatusModalComponent, ngbModalOptions);
      const contentComponentInstance = modalRef.componentInstance;
      contentComponentInstance.subject = 'REJECT ORDER';
      contentComponentInstance.message =
        'Are you sure you want to reject ' + this.selectedValues.length + ' order(s)?';
      contentComponentInstance.action = 'rejectOrder';
      modalRef.result.then((result) => {
        if (result) {
          this.rejectResult = {
            orderno: this.selectedValues,
            statusId: this.orderStatusValue.orderRejected,
            rejectId: parseInt(result.rejectId),
            comment: result.comment,
            createdBy: this.userId
          }
          this.approveOrders(this.rejectResult, 'reject');
        } else {
        }
      });
    } else {
      this.alertService.error(this.errorMsg.SELECT_ORDERS)
    }
  }

  approveOrders(data, title): void {
    this.isLoading = true;
    this.orderService.approveOrders(data).subscribe(
      res => {
        this.isLoading = false;
        if (title === 'accept') {
          this.alertService.success('You have successfully approved ' + this.selectedValues.length + ' order(s)');
        }
        if (title === 'reject') {
          this.alertService.success('You have rejected ' + this.selectedValues.length + ' order(s)')
        }
        console.log(res.statusCode);
        if (res.statusCode == '200') {
          this.pendingOrderList = [];
          this.getPendingOrders();
        }
      },
      error => {
        this.isLoading = false;
        this.alertService.error(error.error.title);
      }
    );
  }
}
