<div id="wrapper">
  <app-sidebar></app-sidebar>
  <div *ngIf="isLoading" id="mydiv">
        <img src="../../../assets/images/Spinner.gif" class="ajax-loader" />
    </div>
  <div id="content-wrapper" class="d-flex flex-column">
    <div id="content">
      <app-topnav></app-topnav>
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a id="allordrs">Reject Reasons</a></li>
        <li class="breadcrumb-menu d-md-down-none"></li>
      </ol>
      <div class="card-header">Reject Reasons</div>
      <div class="container-fluid">
        <form class="ng-untouched ng-pristine ng-valid">
          <div class="form-group form-inline form-io">
            <button type="button" color="primary" rounded="true" mdbwaveseffect="" class="btn btn-primary save-btn"
              routerLink="/add-reason">Add</button>
          </div>
          <table [dtTrigger]="dtTrigger" class="table table-striped table-striped-in" datatable [dtOptions]="dtOptions">
            <thead>
              <tr>
                <th class="bgImage">Serial Number</th>
                <th class="bgImage">Reject Reason</th>
                <th class="bgImage">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let reason of reasonsList;let i=index">
                <td>{{i+1}}</td>
                <td innerHTML="{{reason?.reason}}"></td>
                <td class="cursor">
                  <i routerLink="/reason/{{reason.id}}" class="fas fa-edit"></i>&nbsp;&nbsp;
                  <i class="fa fa-trash-o imgIcon" aria-hidden="true" (click)="confirmModal(reason.id,i)"></i>
                </td>
              </tr>
            </tbody>
          </table>

        </form>

      </div>
      <a class='scroll-to-top rounded' href='#page-top'>
        <i class='fas fa-angle-up'></i>
      </a>