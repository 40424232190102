import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from 'src/app/services/auth.service';
import { TopnavService } from '../../../app/services/topnav.service';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit {
  userName: string;
  userImage: string;
  constructor(private authService: AuthService, private router: Router, private modalService: NgbModal, public topnavService: TopnavService) { }

  ngOnInit(): void {
    this.userName = localStorage.getItem('name');
    console.log(this.topnavService.getDataFromStorage('image') == '');

    this.userImage = this.topnavService.getDataFromStorage('image') === 'null' ||
      this.topnavService.getDataFromStorage('image') == '' ?
      './assets/images/no-image.png' :
      this.topnavService.getDataFromStorage('image');
    console.log(this.userImage);

  }
  showSideNav() {
    document.getElementById("accordionSidebar").style.display = "block";
  }

  openModal() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      size: 'md',
      centered: true,
      keyboard: false
    };
    const modalRef = this.modalService.open(ConfirmationModalComponent, ngbModalOptions);
    const contentComponentInstance = modalRef.componentInstance;
    contentComponentInstance.subject = 'LOGOUT';
    contentComponentInstance.message =
      'Are you sure you want to logout?';
    contentComponentInstance.action = 'logout';
    modalRef.result.then((result) => {
      if (result) {
        this.authService.logout();
      } else {
      }
    });
  }
}
