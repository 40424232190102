import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { TopnavService } from './topnav.service';

@Injectable({
  providedIn: 'root'
})

export class EncrDecrService {
  accessCode = '';
  constructor(private topNavService: TopnavService) { }

  //The set method is use for encrypt the value.
  set() {
    var key = CryptoJS.enc.Utf8.parse('KCortevaSODAthorization@Password');
    var iv = CryptoJS.enc.Utf8.parse('IntVec@CortevaSO');
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse('SPN.Enterprise-EC-SalesOrderDigitalization-WEB-DEV.AG_AP_PPE_01'), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    return encodeURIComponent(encrypted.toString());
  }

  //The get method is use for decrypt the value.
  get(keys, value) {
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var decrypted = CryptoJS.AES.decrypt(value, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}