<div id="wrapper">
  <app-sidebar></app-sidebar>
  <div *ngIf="isLoading" id="mydiv">
        <img src="../../../assets/images/Spinner.gif" class="ajax-loader" />
    </div>
  <div id="content-wrapper" class="d-flex flex-column">
    <div id="content">
      <app-topnav></app-topnav>
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a>My Orders</a></li>
        <li class="breadcrumb-item"><a id="allordrs">All Orders</a></li>
        <li class="breadcrumb-menu d-md-down-none"></li>
      </ol>
      <div class="card-header">All Orders</div>
      <div class="container-fluid">
        <form class="bgWhite">
          <div class="form-group form-inline form-io">
            <div id="DataTables_Table_0_filter" class="dataTables_filter"></div>
          </div>
          <div id="DataTables_Table_0_length" class="dataTables_length"><label>Show
              <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0"
                (change)="onSizeChange(pageSize)" [(ngModel)]="pageSize" class="show_select">
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </select> entries</label>
             

            <select name="lob" aria-controls="DataTables_Table_0" (change)="onLOBChange(lob)" [(ngModel)]="lob"
              class="show_select select_lob">
              <option disabled selected value="">Select LOB</option>
              <option value="">All</option>
              <option value="seed">Seed</option>
              <option value="cp">CP</option>
            </select>

            <select name="status" aria-controls="DataTables_Table_0" (change)="onStatusChange(status)"
              [(ngModel)]="status" class="show_select select_order">
              <option disabled selected value="">Select Order Status</option>
              <option value="">All</option>
              <option *ngFor="let statusValue of orderStatusValue" value="{{statusValue.axstatus_Id}}">
                {{statusValue.statusname}}</option>
            </select>
             <label id="all_order_serach">Search:
                <input type="search" [(ngModel)]="searchText" name="searchText" (input)="searchQuery($event)"
                  placeholder="" aria-controls="DataTables_Table_0" class="data-filter-search"></label>

          </div>
          <table class="table table-striped tableBorder">
            <thead>
              <tr>
                <th
                  [ngClass]="{'ascSort': reverseNumber == true, 'descSort':reverseNumber == false,'bothSort':reverseNumber===''}"
                  (click)="onSort('order_number')">Order Id</th>
                <th
                  [ngClass]="{'ascSort': reverseQty == true, 'descSort':reverseQty == false,'bothSort':reverseQty===''}"
                  (click)="onSort('total_Qty')">Total Quantity</th>
                <th
                  [ngClass]="{'ascSort': reverseAmount == true, 'descSort':reverseAmount == false,'bothSort':reverseAmount===''}"
                  (click)="onSort('total_Amount')">Total Amount</th>
                <th
                  [ngClass]="{'ascSort': dealerCode == true, 'descSort':dealerCode == false,'bothSort':dealerCode===''}"
                  (click)="onSort('dealer_code')">Dealer Code</th>
                <th
                  [ngClass]="{'ascSort': dealerName == true, 'descSort':dealerName == false,'bothSort':dealerName===''}"
                  (click)="onSort('dealer_name')">Dealer Name</th>
                <th [ngClass]="{'ascSort': tsm == true, 'descSort':tsm == false,'bothSort':tsm===''}" (click)="
                  onSort('TSM')">TSM Name</th>
                <th [ngClass]="{'ascSort': territory == true, 'descSort':territory == false,'bothSort':territory===''}"
                  (click)="onSort('Territory')">Territory</th>
                <th [ngClass]="{'ascSort': orderDate == true, 'descSort':orderDate == false,'bothSort':orderDate===''}"
                  (click)="onSort('order_date')">Order Date</th>
                <th
                  [ngClass]="{'ascSort': orderStatus == true, 'descSort':orderStatus == false,'bothSort':orderStatus===''}"
                  (click)="onSort('OrderStatus')">Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let order of orderList">
                <td>{{order?.order_number}}</td>
                <td>{{order?.total_Qty}}</td>
                <td>{{order?.total_Amount}}</td>
                <td>{{order?.dealer_code}}</td>
                <td>{{order?.dealer_name}}</td>
                <td>{{order?.tsm}}</td>
                <td>{{order?.territory}}</td>
                <td>{{order?.order_date | date: 'dd/MMM/yyyy'}}</td>
                <td>{{order?.orderStatus}}</td>
                <td (click)="openDetailsModal(order)" class="cursor">
                  <i class="fa fa-eye eye-icon" aria-hidden="true"></i>
                </td>
              </tr>
            </tbody>
          </table>

          <p class="text-center" *ngIf="orderList.length===0">{{errorMsg.NO_RECORD_FOUND}}</p>
          <div *ngIf="orderList.length" class="row">
            <div class="col-md-12">
              <app-pagination class="float-right" [total]="page" [size]="pageSize" (onChanged)="pageChanged($event)">
              </app-pagination>
            </div>
          </div>

        </form>

      </div>
      <a class='scroll-to-top rounded' href='#page-top'>
        <i class='fas fa-angle-up'></i>
      </a>