<img class="logo" src="https://assets.corteva.com/is/image/dpagco/CortevaLegal_HorColor_RGB_no_tagline"
    alt="Corteva Agriscience™ logo">
<div class="container login-container">
    <div class="row  login-form-row">
        <div class="col-md-12 login-form-1">
            <h3 class="login-h3">Welcome to Corteva SOD</h3>
            <div *ngIf="isLoading" id="mydiv">
                <img src="../../../assets/images/Spinner.gif" class="ajax-loader" />
            </div>
            <form [formGroup]="loginForm" (ngSubmit)="login()">
                <div class=" form-group">
                    <label>Sign into your account!</label>
                    <input type="text" class="form-control" placeholder="Your Email *" email formControlName="email" />
                    <div class="has-error"
                        *ngIf="loginForm.controls['email'].errors && !loginForm.controls['email'].pristine">
                        <div [ngSwitch]="obj(loginForm.controls['email'].errors)[0]">
                            <div *ngSwitchCase="'required'" class="help-block">
                                {{errorMsg.REQUIRED}}</div>
                            <div *ngSwitchCase="'pattern'" class="help-block">
                                {{errorMsg.INVALID_EMAIL}}</div>
                        </div>
                    </div>
                </div>
                <div class="form-group text-center ">
                    <button type="submit" [disabled]="loginForm.invalid" class="btn btn-primary">Login</button>
                </div>
            </form>
        </div>