import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';

import { OrderService } from '../../services/order.service';
import { AlertService } from '../../utilities/alert';
import { ERROR_MESSAGES } from '../../utilities/constants';
import { OrderDetailsComponent } from '../../Layout/order-details/order-details.component';
@Component({
  selector: 'app-admin-ordermanagement',
  templateUrl: './admin-ordermanagement.component.html',
  styleUrls: ['./admin-ordermanagement.component.scss']
})
export class AdminOrderManagementComponent implements OnInit {
  errorMsg = ERROR_MESSAGES;
  isLoading = false;
  orderList: any = [];
  searchText: string;
  sortColumn: any = 'order_date';
  isAscending: any = false;
  page: any;
  lob = '';
  status = '';
  currentPage: any = 1;
  pageSize = 10;
  modalObject: Object;
  closeResult: string;
  reverseNumber: any = '';
  reverseQty: any = '';
  reverseAmount: any = '';
  dealerCode: any = '';
  dealerName: any = '';
  tsm: any = '';
  territory: any = '';
  orderDate: any = '';
  orderStatus: any = '';
  orderStatusValue: any = [];
  existApi: any;
  fromDate: any = '';
  toDate: any = '';

  constructor(private route: ActivatedRoute, private modalService: NgbModal, public orderService: OrderService, private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.fromDate = this.route.snapshot.params.fromDate;
    this.toDate = this.route.snapshot.params.toDate;
    this.status = this.route.snapshot.params.orderStatus;
    this.getOrderStatus();
    this.getAllOrders();
  }

  onSizeChange(size): void {
    this.pageSize = size;
    this.orderList = [];
    this.getAllOrders();
  }

  getOrderStatus() {
    this.orderService.getOrderStatus().subscribe(
      res => {
        if (res.statusCode == 200) {
          this.orderStatusValue = res.orderStatusMaster;
        }
      },
      error => {
        this.alertService.error(error.error.title)
      }
    );
  }

  onLOBChange(lob) {
    this.lob = lob;
    this.orderList = [];
    this.getAllOrders();
  }

  onStatusChange(data) {
    this.status = data;
    this.orderList = [];
    this.getAllOrders();
  }

  searchQuery(event): void {
    this.searchText = event.target.value;
    this.orderList = [];
    this.getAllOrders();
  }

  onSort(key): void {
    this.sortColumn = key;
    if (this.isAscending == true) {
      this.isAscending = false
    } else {
      this.isAscending = !this.isAscending;
    }
    this.orderList = [];
    this.getAllOrders();
    switch (key) {
      case 'order_number':
        this.reverseNumber = this.isAscending;
        this.reverseQty = ''
        this.reverseAmount = '';
        this.dealerCode = '';
        this.dealerName = '';
        this.tsm = '';
        this.territory = '';
        this.orderDate = '';
        this.orderStatus = '';
        break;
      case 'total_Qty':
        this.reverseQty = this.isAscending;
        this.reverseNumber = '';
        this.dealerCode = '';
        this.reverseAmount = '';
        this.dealerName = '';
        this.tsm = '';
        this.territory = '';
        this.orderDate = '';
        this.orderStatus = '';
        break;
      case 'total_Amount':
        this.reverseAmount = this.isAscending;
        this.reverseQty = ''
        this.reverseNumber = '';
        this.dealerCode = '';
        this.dealerName = '';
        this.tsm = '';
        this.territory = '';
        this.orderDate = '';
        this.orderStatus = '';
        break;
      case 'dealer_code':
        this.reverseNumber = '';
        this.reverseQty = '';
        this.reverseAmount = '';
        this.dealerCode = this.isAscending;
        this.dealerName = '';
        this.tsm = '';
        this.territory = '';
        this.orderDate = '';
        this.orderStatus = '';
        break;
      case 'dealer_name':
        this.dealerName = this.isAscending;
        this.reverseNumber = '';
        this.reverseQty = '';
        this.reverseAmount = '';
        this.dealerCode = '';
        this.tsm = '';
        this.territory = '';
        this.orderDate = '';
        this.orderStatus = '';
        break;
      case 'TSM':
        this.tsm = this.isAscending;
        this.reverseNumber = '';
        this.reverseQty = '';
        this.reverseAmount = '';
        this.dealerCode = '';
        this.dealerName = '';
        this.territory = '';
        this.orderDate = '';
        this.orderStatus = '';
        break;
      case 'Territory':
        this.territory = this.isAscending;
        this.reverseNumber = '';
        this.reverseQty = '';
        this.reverseAmount = '';
        this.dealerCode = '';
        this.dealerName = '';
        this.tsm = '';
        this.orderDate = '';
        this.orderStatus = '';
        break;
      case 'order_date':
        this.orderDate = this.isAscending;
        this.reverseNumber = '';
        this.reverseQty = '';
        this.reverseAmount = '';
        this.dealerCode = '';
        this.dealerName = '';
        this.tsm = '';
        this.territory = '';
        this.orderStatus = '';
        break;
      case 'OrderStatus':
        this.orderStatus = this.isAscending;
        this.reverseNumber = '';
        this.reverseQty = '';
        this.reverseAmount = '';
        this.dealerCode = '';
        this.dealerName = '';
        this.tsm = '';
        this.territory = '';
        this.orderDate = '';
        break;
      default:
    }
  }

  openDetailsModal(content) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      size: 'lg',
      centered: true,
      keyboard: false
    };
    const modalRef = this.modalService.open(OrderDetailsComponent, ngbModalOptions);
    const contentComponentInstance = modalRef.componentInstance;
    contentComponentInstance.modalObject = content;
    contentComponentInstance.action = 'all-orders';
    modalRef.result.then((result) => {
      if (result) {
      } else {
      }
    });
  }

  pageChanged(e: number) {
    if (this.currentPage != e) {
      this.currentPage = e;
      this.getAllOrders();
    }
    this.currentPage = e;
  }


  getAllOrders(): void {
    this.isLoading = true;
    var payload = {
      searchText: this.searchText || '',
      sortColumn: this.sortColumn || '',
      isAscending: this.isAscending,
      pageNo: this.currentPage,
      pageSize: this.pageSize,
      lob: this.lob,
      orderStatus: this.status || '',
      fromDate: this.fromDate || '',
      toDate: this.toDate || ''
    }

    if (this.existApi) {
      this.existApi.unsubscribe();
    }

    this.existApi = this.orderService.getAllOrders(payload).subscribe(
      res => {
        this.isLoading = false;
        if (res.statusCode == 200) {
          this.page = res.totalRecords;
          this.orderList = res.orders;
        }
      },
      error => {
        this.isLoading = false;
        this.alertService.error(error.error.title)
      }
    );
  }

}