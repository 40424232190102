import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject, ReplaySubject } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';

import { ReportService } from '../../services/report.service';
import { UserService } from '../../services/user.service';
import { Users } from '../../utilities/models/user';
import { AlertService } from '../../utilities/alert/alert.service';
import { ERROR_MESSAGES } from '../../utilities/constants';
@Component({
  selector: 'app-activity-report',
  templateUrl: './activity-report.component.html',
  styleUrls: ['./activity-report.component.scss']
})
export class ActivityReportComponent implements OnInit {
  userActivityReport: FormGroup;
  errorMsg = ERROR_MESSAGES;
  startDate: any = '';
  endDate: any = '';
  userCode: any = '';
  userId: any = '';
  userList: any = [];
  isLoading = false;
  maxDate: Date;
  canDownload = false;
  itemMultiFilterCtrl: FormControl = new FormControl();
  filteredItemsMulti: ReplaySubject<Array<Users>> = new ReplaySubject<Array<Users>>(1);
  private _onDestroy = new Subject<void>();

  constructor(private userService: UserService, private reportService: ReportService, private alertService: AlertService,
    private formBuilder: FormBuilder) {
    var date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth();
    const currentDate = date.getDate();
    this.maxDate = new Date(currentYear, currentMonth, currentDate);
  }

  ngOnInit(): void {
    this.buildForm();
    this.itemMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterItemsMulti();
      });

    this.userCode = localStorage.getItem('userId');
    this.getUsers();
    this.filteredItemsMulti.next(this.userList.slice());
  }

  buildForm(): void {
    this.userActivityReport = this.formBuilder.group({
      usersList: [''],
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
    });
  }

  trackByFn(): any {
    return null;
  }

  filterItemsMulti(): any {
    if (!this.userList) {
      return;
    }
    let search = this.itemMultiFilterCtrl.value;
    if (!search) {
      this.filteredItemsMulti.next(this.userList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredItemsMulti.next(
      this.userList.filter(item => item.emp_name.toLowerCase().indexOf(search) > -1)
    );
  }

  compareFn(industries, optionindustries): boolean {
    return industries && optionindustries ? industries.id === optionindustries.id :
      industries === optionindustries;
  }


  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getUsers(): void {
    var postData = {
      pageNo: 0,
      pageSize: 0,
      userType: [
        "OTHERUSER", "DEALER", "EMPLOYEE"
      ]
    }
    this.userService.getUsers(postData).subscribe(
      res => {
        this.userList = res.userList;
        this.filteredItemsMulti.next(this.userList.slice());
      },
      error => {
        this.alertService.error(error.error.title);
      }
    );
  }

  onEndDateChanged(event) {
    this.endDate = event.value;
    if (this.endDate < this.startDate) {
      this.canDownload = true;
      this.alertService.error('To Date should be greater than From Date ');
      return;
    } else {
      this.canDownload = false;

    }
  }

  onStartDateChanged(event) {
    this.startDate = event.value;
    if (this.startDate > this.endDate) {
      this.canDownload = true;
      this.alertService.error('From Date should be less than To Date ');
      return;
    } else {
      this.canDownload = false;
    }
  }
  getUserReport() {
    var payload = {
      startDate: moment(this.startDate).format('YYYY-MM-DD'),
      endDate: moment(this.endDate).format('YYYY-MM-DD'),
      userCode: this.userActivityReport.value.usersList,
      createdBy: this.userCode
    }
    if (this.startDate === '' || this.endDate === '') {
      this.alertService.error('Please select from date and to date');
      return;
    }
    this.isLoading = true;
    this.reportService.getUserActivityReport(payload).subscribe(
      res => {
        this.isLoading = false;
        if (res.downloadUrl == null) {
          this.alertService.error(this.errorMsg.NO_RECORD_FOUND);
          return;
        } else {
          window.open(res.downloadUrl);
        }
      },
      error => {
        this.isLoading = false;
        this.alertService.error(error.error.title)
      }
    );
  }

}
