<div id="wrapper">
    <app-sidebar></app-sidebar>
    <div *ngIf="isLoading" id="mydiv">
        <img src="../../../assets/images/Spinner.gif" class="ajax-loader" />
    </div>
    <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
            <app-topnav></app-topnav>

        </div>
    </div>