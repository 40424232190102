import { Component, Input, OnInit, Inject, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
  @Input('subject') subject;
  @Input('action') action;
  @Input('message') message;
  constructor(private authService: AuthService, public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  confirm(): void {
    if (this.action == 'logout') {
    }
    this.activeModal.close(this.action);
  }

  cancel(): void {
    this.activeModal.close();
  }
}
