import { Component, Input, OnInit, Inject, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {
  @Input('modalObject') modalObject;
  @Input('action') action;
  acceptResult: any;
  selectedValues = [];
  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  acceptOrder(orderId) {
    this.activeModal.close({ orderId: orderId, action: 'accept' });
  }

  rejectOrder(orderId) {
    this.activeModal.close({ orderId: orderId, action: 'reject' });
  }

}
