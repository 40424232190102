import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiBaseObject } from './../utilities/models/api-base-object.model';
import { ApiService } from './../services/api.service';
import { API_ENDPOINTS } from './../utilities/api-endpoints-constants';
@Injectable()

export class OrderService {
    private apiObject: ApiBaseObject;
    constructor(private apiService: ApiService) { }

    getAllOrders(data): any {
        this.apiObject = this.apiService.setApiCallObject(API_ENDPOINTS.GET_ALL_ORDERS + '?searchText=' + data.searchText + '&sortColumn=' + data.sortColumn
            + '&isAscending=' + data.isAscending + '&lob=' + data.lob + '&orderStatus=' + data.orderStatus + '&pageNo=' + data.pageNo
            + '&pageSize=' + data.pageSize + '&fromdate=' + data.fromDate +
            '&todate=' + data.toDate, {}, true);
        return this.apiService.getApi(this.apiObject).pipe(
            map((response) => {
                return response;
            },
                (error) => {
                    return error;
                }
            ));
    }

    getRejectReasons(): any {
        this.apiObject = this.apiService.setApiCallObject(API_ENDPOINTS.GET_REJECT_REASONS, {}, true);
        return this.apiService.getApi(this.apiObject).pipe(
            map((response) => {
                return response;
            },
                (error) => {
                    return error;
                }
            ));
    }

    getReasonById(id): any {
        this.apiObject = this.apiService.setApiCallObject(API_ENDPOINTS.GET_REJECT_REASONS + '?id=' + id, {}, true);
        return this.apiService.getApi(this.apiObject).pipe(
            map((response) => {
                return response;
            },
                (error) => {
                    return error;
                }
            ));
    }

    saveRejectReasons(postData): any {
        this.apiObject = this.apiService.setApiCallObject(API_ENDPOINTS.SAVE_REJECT_REASON, postData,
            true);
        return this.apiService.postApi(this.apiObject).pipe(
            map((response) => {
                return response;
            },
                (error) => {
                    return error;
                }
            ));
    }

    deleteRejectReasons(postData): any {
        this.apiObject = this.apiService.setApiCallObject(API_ENDPOINTS.DELETE_REJECT_REASON, postData,
            true);
        return this.apiService.postApi(this.apiObject).pipe(
            map((response) => {
                return response;
            },
                (error) => {
                    return error;
                }
            ));
    }

    getPendingOrders(data): any {
        this.apiObject = this.apiService.setApiCallObject(API_ENDPOINTS.GET_PENDING_ORDERS + '?userCode=' + data.userCode + '&searchText=' + data.searchText +
            '&sortColumn=' + data.sortColumn + '&isAscending=' + data.isAscending + '&pageNo=' + data.pageNo + '&pageSize=' + data.pageSize + '&fromdate=' + data.fromDate +
            '&todate=' + data.toDate, {}, true);
        return this.apiService.getApi(this.apiObject).pipe(
            map((response) => {
                return response;
            },
                (error) => {
                    return error;
                }
            ));
    }

    getOrderStatus(): any {
        this.apiObject = this.apiService.setApiCallObject(API_ENDPOINTS.GET_ORDER_STATUS, {}, true);
        return this.apiService.getApi(this.apiObject).pipe(
            map((response) => {
                return response;
            },
                (error) => {
                    return error;
                }
            ));
    }

    approveOrders(postData): any {
        this.apiObject = this.apiService.setApiCallObject(API_ENDPOINTS.APPROVE_ORDER, postData,
            true);
        return this.apiService.postApi(this.apiObject).pipe(
            map((response) => {
                return response;
            },
                (error) => {
                    return error;
                }
            ));
    }

}
