<div id="wrapper">
    <app-sidebar></app-sidebar>
    <div *ngIf="isLoading" id="mydiv">
        <img src="../../../assets/images/Spinner.gif" class="ajax-loader" />
    </div>
    <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
            <app-topnav></app-topnav>
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a>User Management</a>
                </li>
                <li class="breadcrumb-item">
                    <a id="dealer_users">Dealer Users</a>
                </li>
                <li class="breadcrumb-menu d-md-down-none"></li>
            </ol>
            <div class="card-header">Dealer Users</div>
            <div class="container-fluid">
                <form class="form-group form-inline">
                    <table [dtTrigger]="dtTrigger" class="table table-striped table-striped-in" datatable
                        [dtOptions]="dtOptions">
                        <thead>
                            <tr>
                                <th>Dealer Code</th>
                                <th>Dealer Name</th>
                                <th>Email ID</th>
                                <th>Mobile Number</th>
                                <th>TSM Name</th>
                                <th>Status</th>
                                <th>Territory Assigned</th>
                                <th>Role</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let dealer of dealerUserList">
                                <td>{{dealer?.emp_code}}</td>
                                <td>{{dealer?.emp_name}}</td>
                                <td class="wordBreak">{{dealer?.emp_email}}</td>
                                <td>{{dealer?.emp_phone}}</td>
                                <td>{{dealer?.tsm}}</td>
                                <td>{{dealer?.status}}</td>
                                <td>{{dealer?.territory_assigned}}</td>
                                <td>{{dealer?.role}}</td>
                            </tr>
                        </tbody>
                    </table>

                </form>
            </div>
        </div>
    </div>
</div>