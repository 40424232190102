import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../services/auth.service';
@Component({
  selector: 'app-redirect-component',
  templateUrl: './redirect-component.component.html',
  styleUrls: ['./redirect-component.component.scss']
})
export class RedirectComponent implements OnInit {
  isLoading = false;
  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.authService.getUserDetails();
    this.isLoading = false;
  }

}
