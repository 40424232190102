import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiBaseObject } from './../utilities/models/api-base-object.model';
import { ApiService } from './../services/api.service';
import { API_ENDPOINTS } from './../utilities/api-endpoints-constants';

@Injectable()

export class ProductService {
    private apiObject: ApiBaseObject;
    constructor(private apiService: ApiService) { }

    getProduct(): any {
        this.apiObject = this.apiService.setApiCallObject(API_ENDPOINTS.GET_PRODUCT, {}, true);
        return this.apiService.getApi(this.apiObject).pipe(
            map((response) => {
                return response;
            },
                (error) => {
                    return error;
                }
            ));
    }

    getTerritories(): any {
        this.apiObject = this.apiService.setApiCallObject(API_ENDPOINTS.GET_TERRITORY, {}, true);
        return this.apiService.getApi(this.apiObject).pipe(
            map((response) => {
                return response;
            },
                (error) => {
                    return error;
                }
            ));
    }

    getProductTypes(lob): any {
        this.apiObject = this.apiService.setApiCallObject(API_ENDPOINTS.GET_PRODUCT_TYPES + '?productLOB=' + lob, {}, true);
        return this.apiService.getApi(this.apiObject).pipe(
            map((response) => {
                return response;
            },
                (error) => {
                    return error;
                }
            ));
    }


    getProductById(id): any {
        this.apiObject = this.apiService.setApiCallObject(API_ENDPOINTS.GET_PRODUCT + '?ProductID=' + id, {}, true);
        return this.apiService.getApi(this.apiObject).pipe(
            map((response) => {
                return response;
            },
                (error) => {
                    return error;
                }
            ));
    }

    saveProduct(postData): any {
        this.apiObject = this.apiService.setApiCallObject(API_ENDPOINTS.SAVE_PRODUCT, postData,
            true);
        return this.apiService.postApi(this.apiObject).pipe(
            map((response) => {
                return response;
            },
                (error) => {
                    return error;
                }
            ));
    }
}
