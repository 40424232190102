import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { MsalModule } from '@azure/msal-angular';
import { HttpClientModule } from '@angular/common/http';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { ChartsModule } from 'ng2-charts';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { EncrDecrService } from './services/encrDecr.service';

import { OAuthSettings } from '../environments/environment';
import { ProductService } from './services/product.service';
import { OrderService } from './services/order.service';
import { PagerService } from './services/pager.service';
import { UserService } from './services/user.service';
import { ApiService } from './services/api.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminOrderManagementComponent } from './Feature/admin-ordermanagement/admin-ordermanagement.component';
import { AdminLoginComponent } from './Auth/admin-login/admin-login.component';
import { HomeComponent } from './Feature/home/home.component';
import { AdminUserManagement } from './Feature/admin-usermanagement/admin-usermanagement';
import { DealerUserManagement } from './Feature/dealer-usermanagement/dealer-usermanagement';
import { ProfileComponent } from './Feature/profile/profile.component';
import { SidebarComponent } from './Layout//sidebar/sidebar.component';
import { TopnavComponent } from './Layout/topnav/topnav.component';
import { AdminProductManagementComponent } from './Feature/admin-product-management/admin-product-management.component';
import { AddUserComponent } from './Feature/adduser/adduser.component';
import { PendingOrdersComponent } from './Feature/pending-orders/pending-orders.component';
import { OtherUserComponent } from './Feature/otheruser/otheruser.component';
import { ProductDetailsComponent } from './Feature/product-details/product-details.component';
import { AlertModule } from './../app/utilities/alert';
import { ConfirmationModalComponent } from './Layout/confirmation-modal/confirmation-modal.component';
import { ReasonsListComponent } from './Feature/reasons-list/reasons-list.component';
import { AddReasonsComponent } from './Feature/add-reasons/add-reasons.component';
import { OrderDetailsComponent } from './Layout/order-details/order-details.component';
import { Pagination } from './Layout/pagination/pagination.component';
import { NotificationsComponent } from './Feature/notifications/notifications.component';
import { FlowGuard, LoginFlowGuard } from './guards/flow.gurad';
import { EditNotificationsComponent } from './Feature/edit-notifications/edit-notifications.component';
import { ReportsComponent } from './Feature/reports/reports.component';
import { OrderStatusModalComponent } from './Layout/order-status-modal/order-status-modal.component';
import { ProductMasterReportComponent } from './Feature/product-master-report/product-master-report.component';
import { OrderReportComponent } from './Feature/order-report/order-report.component';
import { UserMasterReportComponent } from './Feature/user-master-report/user-master-report.component';
import { ActivityReportComponent } from './Feature/activity-report/activity-report.component';
import { ReportService } from './services/report.service';
import { LoadingComponent } from './utilities/loader/loader.component'
import {
  MatSelectSearchModule
} from './Layout/mat-select-search/mat-select-search.module';
import { CustomerLedgerComponent } from './Feature/customer-ledger/customer-ledger.component';
import { RedirectComponent } from './Feature/redirect-component/redirect-component.component';
@NgModule({
  declarations: [
    AppComponent,
    AdminOrderManagementComponent,
    AdminLoginComponent,
    HomeComponent,
    AdminUserManagement,
    DealerUserManagement,
    ProfileComponent,
    SidebarComponent,
    TopnavComponent,
    AdminProductManagementComponent,
    AddUserComponent,
    PendingOrdersComponent,
    OtherUserComponent,
    ProductDetailsComponent,
    ConfirmationModalComponent,
    OrderStatusModalComponent, LoadingComponent,
    ReasonsListComponent,
    AddReasonsComponent,
    OrderDetailsComponent,
    Pagination,
    NotificationsComponent,
    EditNotificationsComponent,
    ReportsComponent,
    ProductMasterReportComponent,
    OrderReportComponent,
    UserMasterReportComponent,
    ActivityReportComponent,
    CustomerLedgerComponent,
    RedirectComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    ChartsModule, MatSelectSearchModule,
    FormsModule, MatFormFieldModule, MatCheckboxModule, MatChipsModule, MatSelectModule,
    ReactiveFormsModule,
    DataTablesModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    AngularEditorModule,
    AlertModule,
    MatDatepickerModule, MatNativeDateModule, MatRippleModule,
    MsalModule.forRoot({
      auth: {
        clientId: OAuthSettings.appId,
        redirectUri: OAuthSettings.redirectUri,
        postLogoutRedirectUri: OAuthSettings.redirectUri
      }
    })
  ],

  exports: [RouterModule],
  providers: [FlowGuard, LoginFlowGuard, EncrDecrService, ReportService, UserService, ApiService, PagerService, ProductService, OrderService],
  bootstrap: [AppComponent]
})
export class AppModule { }
