import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { UserService } from '../../services/user.service';
import { AlertService } from '../../utilities/alert';
import { ERROR_MESSAGES } from '../../utilities/constants';
@Component({
  selector: 'app-otheruser',
  templateUrl: './otheruser.component.html',
  styleUrls: ['./otheruser.component.scss']
})
export class OtherUserComponent implements OnInit {
  errorMsg = ERROR_MESSAGES;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: any = {};
  otherUserList: any;
  isLoading = false;

  constructor(private userService: UserService, private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.getOtherUsers();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      lengthMenu: [10, 20, 50],
      processing: true
    };
  }

  getOtherUsers(): void {
    this.isLoading = true;
    var postData = {
      pageNo: 0,
      pageSize: 0,
      userType: [
        "OTHERUSER"
      ]
    }
    this.userService.getUsers(postData).subscribe(
      res => {
        this.isLoading = false;
        this.otherUserList = res.userList;
        this.dtTrigger.next();
      },
      error => {
        this.isLoading = false;
        this.alertService.error(error.error.title);
      }
    );
  }

}