<div id="wrapper">
  <app-sidebar></app-sidebar>
  <div *ngIf="isLoading" id="mydiv">
    <img src="../../../assets/images/Spinner.gif" class="ajax-loader" />
  </div>
  <div id="content-wrapper" class="d-flex flex-column">
    <div id="content">
      <app-topnav></app-topnav>
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/reports">Reports</a></li>
        <li class="breadcrumb-item"><a id="reports_bcum_col">Transactions</a></li>
        <li class="breadcrumb-item"><a id="pendingapr">Customer Ledger Report</a></li>
      </ol>
      <div class="card-header">Customer Ledger Report</div>
      <div class="container-fluid">
        <form [formGroup]="customerLedgerForm" class="bgWhite">
          <div class="lefger_first">
            <div class="form-group row">
              <div class="col-md-12">
                <label class="col-form-label from_date" for="text-input">From Date<span id="asterisk_red">*</span><span
                    id="vis__hid1"></span>:
                </label>
                <mat-form-field color="primary">
                  <mat-label>Choose from date</mat-label>
                  <input matInput #startDate formControlName="startDate" [max]="maxDate" [matDatepicker]="pickerFrom"
                    (dateChange)="onStartDateChanged($event)" disabled>
                  <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                  <mat-datepicker #pickerFrom disabled="false"></mat-datepicker>
                </mat-form-field>
                <div class="has-error"
                  *ngIf="customerLedgerForm.controls['startDate'].errors && !customerLedgerForm.controls['startDate'].pristine">
                  <div [ngSwitch]="obj(customerLedgerForm.controls['startDate'].errors)[0]">
                    <div *ngSwitchCase="'required'" class="help-block">
                      {{errorMsg.REQUIRED}}</div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">

                <label class="col-form-label to_date" for="text-input">To Date<span id="asterisk_red">*</span><span
                    id="vis__hid"></span>: </label>
                <mat-form-field color="primary">
                  <mat-label>Choose to date</mat-label>
                  <input matInput #endDate formControlName="endDate" [max]="maxDate" [matDatepicker]="pickerTo"
                    (dateChange)="onEndDateChanged($event)" disabled>
                  <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                  <mat-datepicker #pickerTo disabled="false"></mat-datepicker>
                </mat-form-field>
                <div class="has-error"
                  *ngIf="customerLedgerForm.controls['endDate'].errors && !customerLedgerForm.controls['endDate'].pristine">
                  <div [ngSwitch]="obj(customerLedgerForm.controls['endDate'].errors)[0]">
                    <div *ngSwitchCase="'required'" class="help-block">
                      {{errorMsg.REQUIRED}}</div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="leader_select">
                  <label class="col-form-label" for="text-input">Select Dealer<span id="asterisk_red">*<span
                        id="vis__hidfour"></span></span>:</label>
                  <div class="select_field">
                    <mat-form-field>
                      <mat-select placeholder="Select User" class="select__del" #dealersList
                        formControlName="dealersList" name="dealersList" [compareWith]="compareFn">
                        <app-mat-select-search [formControl]="itemMultiFilterCtrl"></app-mat-select-search>
                        <mat-option disabled value="">Select Dealer</mat-option>
                        <mat-option *ngFor="let dealer of filteredItemsMulti | async; trackBy:trackByFn;"
                          value="{{dealer.emp_code}}"> {{dealer.emp_name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div class="has-error"
                      *ngIf="customerLedgerForm.controls['dealersList'].errors && !customerLedgerForm.controls['dealersList'].pristine">
                      <div [ngSwitch]="obj(customerLedgerForm.controls['dealersList'].errors)[0]">
                        <div *ngSwitchCase="'required'" class="help-block">
                          {{errorMsg.REQUIRED}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="leader_select">
                  <label class="col-form-label" for="text-input">Select LOB<span id="asterisk_red">*<span
                        id="vis__hidthree"></span></span>: </label>
                  <div class="select_field">
                    <mat-form-field>
                      <mat-select name="lob" #lob formControlName="lob" placeholder="Select Value" class="select__del">
                        <mat-option disabled value="">Select Value</mat-option>
                        <mat-option value="seed">Seed
                        </mat-option>
                        <mat-option value="cp">CP
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div class="has-error"
                      *ngIf="customerLedgerForm.controls['lob'].errors && !customerLedgerForm.controls['lob'].pristine">
                      <div [ngSwitch]="obj(customerLedgerForm.controls['lob'].errors)[0]">
                        <div *ngSwitchCase="'required'" class="help-block">
                          {{errorMsg.REQUIRED}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button type=" button" color="primary" [disabled]="customerLedgerForm.invalid || canDownload" rounded="true"
            (click)="getCustomerReport()" class="btn btn-primary save-btn">Download</button>
        </form>
      </div>
    </div>
  </div>