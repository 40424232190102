import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmationModalComponent } from './../../Layout/confirmation-modal/confirmation-modal.component'
import { ERROR_MESSAGES } from '../../utilities/constants';
import { OrderService } from '../../services/order.service';
import { AlertService } from '../../utilities/alert';

@Component({
  selector: 'app-reasons-list',
  templateUrl: './reasons-list.component.html',
  styleUrls: ['./reasons-list.component.scss']
})
export class ReasonsListComponent implements OnInit {
  dtTrigger: Subject<any> = new Subject();
  dtOptions: any = {};
  reasonsList: any = [];
  userId: any;
  errorMsg = ERROR_MESSAGES;
  isLoading = false;

  constructor(private modalService: NgbModal, private orderService: OrderService, private alertService: AlertService) { }

  ngOnInit(): void {
    this.userId = localStorage.getItem('userId');
    this.getRejectReasons();
    this.dtOptions = {
      retrieve: true,
      pagingType: 'full_numbers',
      pageLength: 10,
      lengthMenu: [10, 20, 50],
      processing: true
    };
  }

  confirmModal(id, index) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      size: 'md',
      centered: true,
      keyboard: false
    };
    const modalRef = this.modalService.open(ConfirmationModalComponent, ngbModalOptions);
    const contentComponentInstance = modalRef.componentInstance;
    contentComponentInstance.subject = 'DELETE REJECT REASON';
    contentComponentInstance.message =
      'Are you sure you want to delete reject reason?';
    contentComponentInstance.action = 'removeImage';
    modalRef.result.then((result) => {
      if (result) {
        this.deleteRejectReason(id, index);
      } else {
      }
    });
  }


  getRejectReasons(): void {
    this.isLoading = true;
    this.orderService.getRejectReasons().subscribe(
      res => {
        this.isLoading = false;
        this.reasonsList = res.rejectReason;
        this.dtTrigger.next();
      },
      error => {
        this.isLoading = false;
        this.alertService.error(error.error.title)
      }
    );
  }

  deleteRejectReason(id, index): void {
    this.isLoading = true;
    var postData = {
      id: id,
      deletedby: this.userId
    }
    this.orderService.deleteRejectReasons(postData).subscribe(
      res => {
        this.isLoading = false;
        if (res.statusCode == 200) {
          this.alertService.success(this.errorMsg.REASON_DELETED);
          this.reasonsList = [];
          this.getRejectReasons();
        }
      },
      error => {
        this.isLoading = false;
        this.alertService.error(error.error.title)
      }
    );
  }
}
