<div id="wrapper">
  <app-sidebar></app-sidebar>
  <div *ngIf="isLoading" id="mydiv">
    <img src="../../../assets/images/Spinner.gif" class="ajax-loader" />
  </div>
  <div id="content-wrapper" class="d-flex flex-column">
    <div id="content">
      <app-topnav></app-topnav>
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/reports">Reports</a></li>
        <li class="breadcrumb-item"><a id="reports_bcum_col">Masters</a></li>
        <li class="breadcrumb-item"><a id="pendingapr">User Master Report</a></li>
      </ol>
      <div class="card-header">User Master Report</div>
      <div class="container-fluid">
        <form class="bgWhite">
          <div class="order-report-container">
            <div class="form-group row">
              <div class="col-md-12">
                <label class="col-form-label" for="text-input">Select Role<span id="asterisk_red">*</span>: </label>
                <select name="roleId" (change)="onRoleChange(roleId)" [(ngModel)]="roleId"
                  class="ml-1 show_select bgWhite">
                  <option selected value="0">All</option>
                  <option value="1">DEALER</option>
                  <option value="2">TSM</option>
                  <option value="3">SSE</option>
                  <option value="4">ADMIN</option>
                </select>
              </div>

              <div class="col-md-12">
                <label class="col-form-label" for="text-input">Select LOB <span id="vis__hid"></span>: </label>
                <select name="lob" (change)="onLOBChange(lob)" [(ngModel)]="lob" class="ml-1 show_select bgWhite">
                  <option selected value="">All</option>
                  <option value="seed">Seed</option>
                  <option value="cp">CP</option>
                </select>
              </div>
            </div>
          </div>
          <button type="button" color="primary" rounded="true" class="btn btn-primary save-btn"
            (click)="getUserMasterReport()">Download</button>
        </form>
      </div>
    </div>
  </div>