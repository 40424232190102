<div id="wrapper">
  <app-sidebar></app-sidebar>
  <div *ngIf="isLoading" id="mydiv">
        <img src="../../../assets/images/Spinner.gif" class="ajax-loader" />
    </div>
  <div id="content-wrapper" class="d-flex flex-column">
    <div id="content">
      <app-topnav></app-topnav>
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a id="prifilrpg" href="#/">Profile</a></li>
      </ol>
      <div class="container-fluid">
        <div class="card-header"><strong>Profile</strong></div>
        <form class="form form-marg" novalidate="">
          <div class="form-position text-center">

            <img *ngIf="profileDetails.imagePath" class="img-style" src="{{profileDetails.imagePath}}">
            <div *ngIf="!profileDetails.imagePath" class="pd-gallery1">
              <h5 class="pd-upload1"> Upload Image </h5>
            </div>
            <div class="text-center text-sm-left mb-2 mb-sm-0 text-margin">
              <span>
                <div type="submit" style="position: relative;" class="btn btn-primary">
                  <input type="file" id="fileImage" (change)="fileUpload($event)" accept="image/*" />
                 <span id="upload_remove"> Upload image</span><i class="fa fa-upload" aria-hidden="true"></i></div></span>
              <span class="ml-3"><button type="button" [disabled]="!imageFile" (click)="confirmModal()"
                  class="btn btn-primary">
                 <span id="upload_remove"> Remove image</span><i class="fa fa-trash" aria-hidden="true"></i></button></span>
              
            </div>

            <div class="mt-3 ml-3 text-left"><i class="infoIcon fa fa-info-circle" aria-hidden="true"></i>
              <span class="ml-2">{{errorMsg.IMAGE_SIZE}}</span> </div>
          </div>
          <div class="row row-padding">
            <div class="col">

              <div class="row">

                <div class="col-12 ">
                  <div class="form-group">
                    <label for="name">Full Name</label>
                    <input class="form-control" name="name" [(ngModel)]="profileDetails.name" readonly id="text"
                      placeholder="Name" type="text">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 ">
                  <div class="form-group"><label for="Designation">Designation</label><input class="form-control"
                      id="company" name="designation" [(ngModel)]="profileDetails.roleName" placeholder="Designation"
                      type="text" readonly></div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 ">
                  <div class="form-group"><label for="employee">Employee ID</label><input class="form-control"
                      id="company" name="id" placeholder="Employee ID" [(ngModel)]="profileDetails.userId" type="text"
                      readonly></div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group"><label for="employee">Email</label><input readonly class="form-control"
                      id="company" placeholder="Email" name="email" type="text" [(ngModel)]="profileDetails.email">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

      </div>
    </div>

  </div>
</div>