import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { UserService } from '../../services/user.service';
import { AlertService } from '../../utilities/alert';
import { ERROR_MESSAGES } from '../../utilities/constants';

@Component({
  selector: 'app-admin-usermanagement',
  templateUrl: './admin-usermanagement.component.html',
  styleUrls: ['./admin-usermanagement.scss']
})
export class AdminUserManagement implements OnInit {
  isLoading = false;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: any = {};
  axUserList: any;
  errorMsg = ERROR_MESSAGES;

  constructor(private userService: UserService, private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.getAXUsers();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      lengthMenu: [10, 20, 50],
      processing: true
    };
  }

  getAXUsers(): void {
    this.isLoading = true;
    var postData = {
      pageNo: 0,
      pageSize: 0,
      userType: [
        "EMPLOYEE"
      ]
    }
    this.userService.getUsers(postData).subscribe(
      res => {
        this.isLoading = false;
        this.axUserList = res.userList;
        this.dtTrigger.next();
      },
      error => {
        this.isLoading = false;
        this.alertService.error(error.error.title);
      }
    );
  }

}
