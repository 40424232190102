<div id="wrapper">
  <app-sidebar></app-sidebar>
  <div *ngIf="isLoading" id="mydiv">
    <img src="../../../assets/images/Spinner.gif" class="ajax-loader" />
  </div>
  <div id="content-wrapper" class="d-flex flex-column">
    <div id="content">
      <app-topnav></app-topnav>
      <div class="container-fluid">
        <div class="addusercontent">
          <div class="card-header"><strong>{{titleValue}} User</strong></div>

          <form [formGroup]="userForm" (ngSubmit)="saveUser(userDetails)" id="adduser">
            <fieldset>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group"><label for="name">Name<span id="asterisk_red">*</span></label>
                    <input class="form-control" id="name" #userName formControlName="userName"
                      placeholder="Enter your name" type="text">
                    <div class="has-error"
                      *ngIf="userForm.controls['userName'].errors && !userForm.controls['userName'].pristine">
                      <div [ngSwitch]="obj(userForm.controls['userName'].errors)[0]">
                        <div *ngSwitchCase="'required'" class="help-block">
                          {{errorMsg.REQUIRED}}</div>
                        <div *ngSwitchCase="'pattern'" class="help-block">
                          {{errorMsg.INVALID_USERNAME}}</div>
                        <div *ngSwitchCase="'maxlength'" class="help-block">
                          {{errorMsg.MAX_LENGTH50}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>

            <fieldset>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group"><label for="name">Email ID<span id="asterisk_red">*</span></label>
                    <input class="form-control" id="name" #email formControlName="email" placeholder="Enter Email Id"
                      type="email">
                    <div class="has-error"
                      *ngIf="userForm.controls['email'].errors && !userForm.controls['email'].pristine">
                      <div [ngSwitch]="obj(userForm.controls['email'].errors)[0]">
                        <div *ngSwitchCase="'required'" class="help-block">
                          {{errorMsg.REQUIRED}}</div>
                        <div *ngSwitchCase="'pattern'" class="help-block">
                          {{errorMsg.INVALID_EMAIL}}</div>
                        <div *ngSwitchCase="'maxlength'" class="help-block">
                          {{errorMsg.MAX_LENGTH64}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </fieldset>

            <fieldset>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group"><label for="name">Mobile No<span id="asterisk_red">*</span></label>
                    <input class="form-control" id="name" #mobileNo formControlName="mobileNo"
                      placeholder="Enter Mobile Number" type="tel">
                    <div class="has-error"
                      *ngIf="userForm.controls['mobileNo'].errors && !userForm.controls['mobileNo'].pristine">
                      <div [ngSwitch]="obj(userForm.controls['mobileNo'].errors)[0]">
                        <div *ngSwitchCase="'required'" class="help-block">
                          {{errorMsg.REQUIRED}}</div>
                        <div *ngSwitchCase="'pattern'" class="help-block">
                          {{errorMsg.MOBILE_NO}}</div>
                        <div *ngSwitchCase="'maxlength'" class="help-block">
                          {{errorMsg.MOBILE_NO}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </fieldset>

            <div class="role_territory">
              <div class="form-group form-gr">

                <label id="lable_for_role" for="name">Select Role<span id="asterisk_red">*</span></label>
                <mat-form-field style="width:400px;">
                  <mat-select formControlName="userRole" #userRole placeholder="Select Role" name="userRole">
                    <mat-option disabled [value]="0">Select Role</mat-option>
                    <mat-option [value]="3">SSE</mat-option>
                    <mat-option [value]="4">Admin</mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="has-error"
                  *ngIf="userForm.controls['userRole'].errors && !userForm.controls['userRole'].pristine">
                  <div [ngSwitch]="obj(userForm.controls['userRole'].errors)[0]">
                    <div *ngSwitchCase="'required'" class="help-block">
                      {{errorMsg.REQUIRED}}</div>
                  </div>
                </div>
                <div class="active_inactive">
                  <p>Inactive</p>
                  <label class="switch">
                    <input type="checkbox" [checked]="userDetails.status==='ACTIVE' ? true : false"
                      (change)="onStatusChange($event)">
                    <span name="status" class="slider round slider-mar"></span></label>
                  <p>Active</p>
                </div>
              </div>
            </div>

            <div class="createuserbtn">
              <fieldset>
                <button type="submit" color="primary" [disabled]="userForm.invalid || saveInProgress" rounded="true"
                  mdbwaveseffect="" id="contact-submit" class="btn btn-primary pd-btn-save" tabindex="0">Save</button>
              </fieldset>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>