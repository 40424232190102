
import {
    ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot
} from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';


@Injectable()
export class LoginFlowGuard implements CanActivate {
    constructor(private router: Router, public authService: AuthService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.authService.authenticated) {
            this.router.navigate(['/redirect']);
            return false;
        } else {
            return true;
        }
    }
}


@Injectable()
export class FlowGuard implements CanActivate {

    constructor(public authService: AuthService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.authService.authenticated) {
            return true;
        } else {
            this.router.navigate(['/']);
            return false;
        }
    }
}