<div id="wrapper">
    <app-sidebar></app-sidebar>
    <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
            <app-topnav></app-topnav>
            <ol class="breadcrumb">

                <li class="breadcrumb-item"><a id="pendingapr">Edit Notificationt</a></li>

            </ol>
            <div class="card-header">Edit Notification </div>
            <div class="container-fluid">
                <form>
                    <div class="edit-noti-select">
                        <div class="col-md-4"><select class="form-control form-control-lg" id="select2" name="select2">
                                <option value="0">Please select</option>
                                <option value="1">Option #1</option>
                                <option value="2">Option #2</option>
                                <option value="3">Option #3</option>
                            </select></div>
                        <div class="col-md-4"><select class="form-control form-control-lg" id="select2" name="select2">
                                <option value="0">Please select</option>
                                <option value="1">Option #1</option>
                                <option value="2">Option #2</option>
                                <option value="3">Option #3</option>
                            </select></div>
                        <div class="col-md-4"><select class="form-control form-control-lg" id="select2" name="select2">
                                <option value="0">Please select</option>
                                <option value="1">Option #1</option>
                                <option value="2">Option #2</option>
                                <option value="3">Option #3</option>
                            </select>
                        </div>
                    </div>

                    <textarea rows="6" name="reasonDescription" id="notificationDesc" placeholder="Enter text here..."
                        maxlength="250"></textarea>

                    <button type="button" color="primary" rounded="true" class="btn btn-primary save-btn">Save
                    </button>
                </form>

            </div>

        </div>