import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiBaseObject } from './../utilities/models/api-base-object.model';
import { ApiService } from './../services/api.service';
import { API_ENDPOINTS } from './../utilities/api-endpoints-constants';

@Injectable()

export class ReportService {
    private apiObject: ApiBaseObject;
    constructor(private apiService: ApiService) { }


    getOrderReport(data): any {
        this.apiObject = this.apiService.setApiCallObject(API_ENDPOINTS.GET_ORDER_REPORT, data, true);
        return this.apiService.postApi(this.apiObject).pipe(
            map((response) => {
                return response;
            },
                (error) => {
                    return error;
                }
            ));
    }

    getUserActivityReport(data): any {
        this.apiObject = this.apiService.setApiCallObject(API_ENDPOINTS.GET_USER_REPORT, data, true);
        return this.apiService.postApi(this.apiObject).pipe(
            map((response) => {
                return response;
            },
                (error) => {
                    return error;
                }
            ));
    }

    getUserMasterReport(data): any {
        this.apiObject = this.apiService.setApiCallObject(API_ENDPOINTS.GET_USER_MASTER_REPORT, data, true);
        return this.apiService.postApi(this.apiObject).pipe(
            map((response) => {
                return response;
            },
                (error) => {
                    return error;
                }
            ));
    }

    getProductMasterReport(data): any {
        this.apiObject = this.apiService.setApiCallObject(API_ENDPOINTS.GET_PRODUCT_MASTER_REPORT, data, true);
        return this.apiService.postApi(this.apiObject).pipe(
            map((response) => {
                return response;
            },
                (error) => {
                    return error;
                }
            ));
    }

    getCustomerReport(data): any {
        this.apiObject = this.apiService.setApiCallObject(API_ENDPOINTS.GET_CUSTOMER_LEDGER_REPORT +
            '?fromdate=' + data.startDate + '&todate=' + data.endDate + '&customeraccountnumber=' + data.dealer + '&lob=' + data.lob, {}, true);
        return this.apiService.getApi(this.apiObject).pipe(
            map((response) => {
                return response;
            },
                (error) => {
                    return error;
                }
            ));
    }

    getOrderCount(data): any {
        this.apiObject = this.apiService.setApiCallObject(API_ENDPOINTS.GET_ORDER_COUNT, data, true);
        return this.apiService.postApi(this.apiObject).pipe(
            map((response) => {
                return response;
            },
                (error) => {
                    return error;
                }
            ));
    }

    getSalesReport(data): any {
        this.apiObject = this.apiService.setApiCallObject(API_ENDPOINTS.GET_SALES_DATA, data, true);
        return this.apiService.postApi(this.apiObject).pipe(
            map((response) => {
                return response;
            },
                (error) => {
                    return error;
                }
            ));
    }
}
