import { Component, OnInit } from '@angular/core';
import * as moment from 'moment-timezone';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReportService } from '../../services/report.service';
import { AlertService } from '../../utilities/alert/alert.service';
import { ERROR_MESSAGES } from '../../utilities/constants';
import { TopnavService } from 'src/app/services/topnav.service';

@Component({
  selector: 'app-order-report',
  templateUrl: './order-report.component.html',
  styleUrls: ['./order-report.component.scss']
})
export class OrderReportComponent implements OnInit {
  orderReportForm: FormGroup;
  errorMsg = ERROR_MESSAGES;
  startDate: any = null;
  endDate: any = null;
  lob: any = '';
  userId: any = '';
  isLoading = false;
  maxDate: Date;
  canDownload = false;


  constructor(private formBuilder: FormBuilder, private topNav: TopnavService, private reportService: ReportService, private alertService: AlertService) {
    var date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth();
    const currentDate = date.getDate();
    this.maxDate = new Date(currentYear, currentMonth, currentDate);
  }

  ngOnInit(): void {
    this.userId = this.topNav.getDataFromStorage('userId');
    this.buildForm();
  }

  buildForm(): void {
    this.orderReportForm = this.formBuilder.group({
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]]
    });
  }

  onLOBChange(lob) {
    this.lob = lob;
  }

  onEndDateChanged(event) {
    this.endDate = event.value;
    if (this.endDate < this.startDate) {
      this.canDownload = true;
      this.alertService.error('To Date should be greater than From Date ');
      return;
    } else {
      this.canDownload = false;

    }
  }

  onStartDateChanged(event) {
    this.startDate = event.value;
    if (this.startDate > this.endDate) {
      this.canDownload = true;
      this.alertService.error('From Date should be less than To Date ');
      return;
    } else {
      this.canDownload = false;
    }
  }

  getOrderReport() {
    var payload = {
      startDate: moment(this.startDate).format('YYYY-MM-DD'),
      endDate: moment(this.endDate).format('YYYY-MM-DD'),
      lob: this.lob || '',
      createdBy: this.userId
    }
    this.isLoading = true;
    this.reportService.getOrderReport(payload).subscribe(
      res => {
        this.isLoading = false;
        if (res.downloadUrl == null) {
          this.alertService.error(this.errorMsg.NO_RECORD_FOUND);
          return;
        } else {
          window.open(res.downloadUrl);
        }
      },
      error => {
        this.isLoading = false;
        this.alertService.error(error.error.title)
      }
    );
  }
}
