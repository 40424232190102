<div id="wrapper">
  <app-sidebar></app-sidebar>
  <div *ngIf="isLoading" id="mydiv">
    <img src="../../../assets/images/Spinner.gif" class="ajax-loader" />
  </div>
  <div id="content-wrapper" class="d-flex flex-column">
    <div id="content">
      <app-topnav></app-topnav>
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/reports">Reports</a></li>
        <li class="breadcrumb-item"><a id="reports_bcum_col">Masters</a></li>
        <li class="breadcrumb-item"><a id="pendingapr">Product Master Report</a></li>
      </ol>
      <div class="card-header">Product Master Report</div>
      <div class="container-fluid">
        <form class="bgWhite">
          <div class="order-report-container">
            <div class="col-md-6">
              <label class="col-form-label" for="text-input">Select LOB<span id="asterisk_red">*</span>: </label>
              <select name="lob" (change)="onLOBChange(lob)" [(ngModel)]="lob" class="ml-1 show_select bgWhite">
                <option selected disabled value="">Select LOB</option>
                <option value="seed">Seed</option>
                <option value="cp">CP</option>
              </select>
            </div>
          </div>
          <button type="button" color="primary" rounded="true" class="btn btn-primary save-btn"
            (click)="getProductMasterReport()">Download</button>
        </form>
      </div>
    </div>
  </div>