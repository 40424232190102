<!-- pagination -->
<nav *ngIf="pager.totalItems > 0">
    <ul class="pagination pagination-circle pg-amber" *ngIf="pager.pages &&  pager.pages.length > 1">
        <li id="pagination_block" class="page-item" [ngClass]="{disabled:pager.currentPage === 1,cursor:pager.currentPage !== 1}">
            <a class="page-link" (click)="setPage(pager.currentPage - 1)" mdbRippleRadius aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
                <span class="sr-only">Previous</span>
            </a>
        </li>
        <li class="page-item cursor" *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
            <a class="page-link" (click)="setPage(page)" mdbRippleRadius>{{page}}</a>
        </li>
        <li id="pagination_block" class="page-item cursor"
            [ngClass]="{disabled:pager.currentPage === pager.totalPages,cursor:pager.currentPage !== pager.totalPages}">
            <a class="page-link" (click)="setPage(pager.currentPage + 1)" mdbRippleRadius aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
                <span class="sr-only">Next</span>
            </a>
        </li>
    </ul>
</nav>
<!-- pagination ends-->