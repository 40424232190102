<div id="wrapper">
  <app-sidebar></app-sidebar>
  <div *ngIf="isLoading" id="mydiv">
    <img src="../../../assets/images/Spinner.gif" class="ajax-loader" />
  </div>
  <div id="content-wrapper" class="d-flex flex-column">
    <div id="content">
      <app-topnav></app-topnav>
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a>Product Management</a></li>
      </ol>
      <div class="container-fluid">
        <div class="panel panel-default" style="margin-bottom:0px;border-bottom:none">
          <div class="card-header ml-0">Product List</div>
        </div>
        <div style="border:1px solid #ddd">
          <form>
            <div class="form-group form-inline form-io">
            </div>
            <table [dtTrigger]="dtTrigger" class="table table-striped table-striped-in" datatable
              [dtOptions]="dtOptions">
              <thead>
                <tr>
                  <th>Item Code</th>
                  <th>Product Type</th>
                  <th>Product</th>
                  <th>LOB</th>
                  <th>Item Price</th>
                  <th>MOQ</th>
                  <th>Status</th>
                  <th>Description</th>
                  <th class="bgImage">Item Image</th>
                  <th class="bgImage">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let product of productListData">
                  <td>{{product?.product_Id}}</td>
                  <td>{{product?.productDetails?.productType?.product_type_name}}</td>
                  <td>{{product?.product_name}}</td>
                  <td>{{product?.product_lob}}</td>
                  <td>{{product?.productPricing[0]?.product_Price}}</td>
                  <td>{{product?.productDetails?.product_minimum_Order_Quantity}}</td>
                  <td>{{product.isActive==true?'ACTIVE':'INACTIVE'}}</td>
                  <td innerHTML="{{product?.productDetails?.product_Description}}"></td>
                  <td><img class="imageSize"
                      src="{{product.productImage.length ? product.productImage[0].product_ImageURL : 'assets/images/no-image.png'}}" />
                  </td>
                  <td routerLink="/product-details/{{product.product_Id}}" class="cursor">
                    <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>