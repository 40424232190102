import { Component, Input, OnInit, Output, OnChanges, SimpleChanges, SimpleChange, EventEmitter } from '@angular/core';
import { PagerService } from 'src/app/services/pager.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class Pagination implements OnChanges, OnInit {

  constructor(private pagerService: PagerService) { }

  @Input() total: number;

  @Input() size: number = 25;

  currentPage: number = 1;

  @Output() onChanged = new EventEmitter<number>();

  pager: any = {
    totalPages: 1
  };

  lastPage: number = 1;


  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.total) {

      const total: SimpleChange = changes.total;

      this.total = total.currentValue;

    }

    this.setPage(this.currentPage);

  }

  setPage(page: number = 1) {

    if (page < 1 || page > this.pager.totalPages) {
      page = 1;
    }

    // get pager object from service
    this.pager = this.pagerService.getPager(this.total, page, this.size);

    if (this.pager.totalPages < this.pager.currentPage) {
      this.pager.currentPage -= 1;
      this.pager = this.pagerService.getPager(this.total, this.pager.currentPage, this.size);
    }

    //if (this.pager.currentPage != this.lastPage) {
    this.lastPage = this.pager.currentPage;
    this.onChanged.emit(this.pager.currentPage);
    this.currentPage = this.pager.currentPage;
    //}
  }
}