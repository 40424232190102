<div id="wrapper">
  <app-sidebar></app-sidebar>
  <div *ngIf="isLoading" id="mydiv">
    <img src="../../../assets/images/Spinner.gif" class="ajax-loader" />
  </div>
  <div id="content-wrapper" class="d-flex flex-column">
    <div id="content">
      <app-topnav></app-topnav>
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/product">Product Management</a></li>
        <li class="breadcrumb-item"><a id="productmngmnt">Product Details</a></li>
      </ol>
      <div class="card-header">Product Details</div>
      <div class="container-fluid">
        <div class="proddetails">
          <div class="product_details_internal">
            <div class="row">
              <div class="col-md-4">
                <div class="col-md-12 img_ulpoader">
                  <img *ngIf="imageFile" src="{{imageFile}}" class="pd-gallery1">
                  <div *ngIf="!imageFile" class="pd-gallery1 ">
                    <h5 class="pd-upload1"> Upload Product Image
                      </h5>
                  </div>
                </div>
                <div class="pd-btn">
                  <div style="position: relative;" type="submit" class="btn btn-primary pd-upload">
                    <input type="file" (change)="readUrl($event)" accept="image/*" />
                    Upload image</div>
                </div>
                <div class="mt-3 ml-3 text-left"><i class="infoIcon fa fa-info-circle" aria-hidden="true"></i>
                  <span class="ml-2">{{errorMsg.IMAGE_SIZE}}</span>    <span id="asterisk_red"> * </span> </div>
              </div>
              <div class="col-md-8 ">
                <form [formGroup]="productForm" class="form-horizontal" (ngSubmit)="saveProductDetails(productDetails)">
                  <div class="row">
                    <div class="col-md-6">
                      <label for="">Item Code</label>
                      <div class="form-group">
                        <input type="text" class="form-control disableCursor" name="product_Id"
                          [(ngModel)]="productDetails.product_Id" [ngModelOptions]="{standalone: true}"
                          placeholder="Item code" readonly>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="">Product Name</label>
                        <input type="text" class="form-control disableCursor" [(ngModel)]="productDetails.product_name"
                          [ngModelOptions]="{standalone: true}" placeholder="Product Name" readonly name="productName">
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="">Item Price</label>
                        <input type="text" class="form-control disableCursor" name="productPrice"
                          [(ngModel)]="productPrice" [ngModelOptions]="{standalone: true}" placeholder="Item Price"
                          readonly>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="">LOB</label>
                        <input type="text" class="form-control disableCursor" [(ngModel)]="productDetails.product_lob"
                          [ngModelOptions]="{standalone: true}" placeholder="LOB" readonly name="productLob">
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="">Alias Product Name<span id="asterisk_red">*</span></label>
                        <input type="text" class="form-control" formControlName="aliasProductName" #aliasProductName
                          placeholder="Product Name" name="aliasProductName">
                        <div class="has-error"
                          *ngIf="productForm.controls['aliasProductName'].errors && !productForm.controls['aliasProductName'].pristine">
                          <div [ngSwitch]="obj(productForm.controls['aliasProductName'].errors)[0]">
                            <div *ngSwitchCase="'required'" class="help-block">
                              {{errorMsg.REQUIRED}}</div>
                            <div *ngSwitchCase="'pattern'" class="help-block">
                              {{errorMsg.PRODUCT_ALIAS}}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <label for="">Product Type<span id="asterisk_red">*</span></label>
                      <mat-form-field>
                        <mat-select formControlName="productType" #productType placeholder="Select Type"
                          name="productType">
                          <mat-option selected disabled [value]=""> Select Type </mat-option>
                          <mat-option *ngFor="let type of productTypeList; trackBy:trackByFn;" [value]="type.id">
                            {{type.product_type_name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <div class="has-error"
                        *ngIf="productForm.controls['productType'].errors && !productForm.controls['productType'].pristine">
                        <div [ngSwitch]="obj(productForm.controls['productType'].errors)[0]">
                          <div *ngSwitchCase="'required'" class="help-block">
                            {{errorMsg.REQUIRED}}</div>
                        </div>
                      </div>
                    </div>


                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <label for="">Territory<span id="asterisk_red">*</span></label>
                      <mat-form-field>
                        <mat-select formControlName="productTerritory" #productTerritory placeholder="Select Territory"
                          multiple="true" name="productTerritory">
                          <mat-option #allSelected (click)="toggleAllSelection()" [value]="0">All</mat-option>
                          <mat-option *ngFor="let territory of territoryList; trackBy:trackByFn;"
                            [value]="territory.territory_code" (click)="tosslePerOne(allSelected.viewValue)">
                            {{territory.territory_group_desc}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <div class="has-error"
                        *ngIf="productForm.controls['productTerritory'].errors && !productForm.controls['productTerritory'].pristine">
                        <div [ngSwitch]="obj(productForm.controls['productTerritory'].errors)[0]">
                          <div *ngSwitchCase="'required'" class="help-block">
                            {{errorMsg.REQUIRED}}</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="">Minimum order quantity<span id="asterisk_red">*</span></label>
                        <input type="number" class="form-control" name="productMinQty" #productMinQty
                          formControlName="productMinQty" placeholder="Minimum order quantity">
                        <div class="has-error"
                          *ngIf="productForm.controls['productMinQty'].errors && !productForm.controls['productMinQty'].pristine">
                          <div [ngSwitch]="obj(productForm.controls['productMinQty'].errors)[0]">
                            <div *ngSwitchCase="'required'" class="help-block">
                              {{errorMsg.REQUIRED}}</div>
                            <div *ngSwitchCase="'pattern'" class="help-block">
                              {{errorMsg.MOQ_DECIMAL_VALUE}}</div>
                            <div *ngSwitchCase="'min'" class="help-block">
                              {{errorMsg.MOQ_VALUE}}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <label for="">Description<span id="asterisk_red">*</span></label>
                      <angular-editor [config]="editorConfig" [placeholder]="'Enter text here...'"
                        name="productDescription" id="productDesc" #productDescription
                        formControlName="productDescription"></angular-editor>
                      <div class="has-error"
                        *ngIf="productForm.controls['productDescription'].errors && !productForm.controls['productDescription'].pristine">
                        <div [ngSwitch]="obj(productForm.controls['productDescription'].errors)[0]">
                          <div *ngSwitchCase="'required'" class="help-block">
                            {{errorMsg.REQUIRED}}</div>
                          <div *ngSwitchCase="'maxlength'" class="help-block">
                            {{errorMsg.MAX_LENGTH500}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group text-right">
                    <button type="submit" [disabled]="productForm.invalid || !imageFile"
                      class="btn btn-primary pd-btn-save">Save</button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>