<nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <a id="bar_hide" href="javascript:void(0)"(click)="showSideNav()" (click)="topnavService.toggleSideNav()"><span
            class="fa fa-fw fa-bars fa-2x"></span></a>
             <a id="show_sidenav" (click)="showSideNav()" ><span
            class="fa fa-fw fa-bars fa-2x"></span></a>
           
   
    <ul class="navbar-nav ml-auto">
        <li class="nav-item dropdown no-arrow d-sm-none">
        </li>
        <li class="nav-item dropdown no-arrow mx-1">
            <a class="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
            </a>
        </li>
        <div class="topbar-divider d-none d-sm-block"></div>
        <li class="nav-item dropdown no-arrow">
            <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{userName}}</span>
                <img src="{{userImage}}" style="width: 35px;">
            </a>
            <div routerLinkActive="activeTab" class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                aria-labelledby="userDropdown">
                <a class="dropdown-item profileitms_user" routerLink="/profile">
                    <i class="fa fa-user"></i>
                    Profile
                </a>
                <a class="dropdown-item cursor" (click)="openModal()">
                    <i class="fa fa-sign-out"></i>
                    Logout
                </a>
            </div>
        </li>
    </ul>
    
</nav>

