import { Component, Input, OnInit, Inject, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { OrderService } from 'src/app/services/order.service';
import { AlertService } from 'src/app/utilities/alert';
import { ERROR_MESSAGES } from '../../utilities/constants';

@Component({
  selector: 'app-order-status-modal',
  templateUrl: './order-status-modal.component.html',
  styleUrls: ['./order-status-modal.component.scss']
})
export class OrderStatusModalComponent implements OnInit {
  rejectId: any = 0;
  comment: any;
  reasonsList: any = [];
  @Input('subject') subject;
  @Input('action') action;
  @Input('message') message;
  errorMsg = ERROR_MESSAGES;
  showErrorMsg: boolean = false;
  commentError: any;
  rejectIdRequired: string;

  constructor(private orderService: OrderService, private alertService: AlertService, public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    if (this.action === 'rejectOrder') {
      this.getRejectReasons();
    }
  }

  getRejectReasons(): void {
    this.orderService.getRejectReasons().subscribe(
      res => {
        this.reasonsList = res.rejectReason;
      },
      error => {
        this.alertService.error(error.error.title)
      }
    );
  }

  confirm(): void {
    if (this.action === 'rejectOrder') {
      if (this.rejectId === 0) {
        this.showErrorMsg = true;
        this.rejectIdRequired = ERROR_MESSAGES.REQUIRED;
        return;
      }
      this.activeModal.close({
        comment: this.comment,
        rejectId: this.rejectId
      });
    } else {
      this.activeModal.close({
        comment: this.comment
      });
    }
    this.activeModal.close(this.action);
  }

  cancel(): void {
    this.activeModal.close();
  }
}
