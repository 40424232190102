import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatOption } from '@angular/material/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { COMMONFORMVALIDATIONRULE } from '../../utilities/formValidation';
import { ProductService } from 'src/app/services/product.service';
import { AlertService } from '../../utilities/alert';
import { ERROR_MESSAGES } from '../../utilities/constants';
import { editorConfig } from '../../utilities/textEditor';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {
  @ViewChild('allSelected') private allSelected: MatOption;
  isLoading = false;
  editorConfig = editorConfig;
  productForm: FormGroup;
  imageFile: any = '';
  productLOB: any = 'Seed';
  productDetails: any = {
    productDetails: {
      product_minimum_Order_Quantity: '',
      product_Description: ''
    },
    productImage: {},
    productPricing: [{
      product_Price: 0
    }]
  };
  productPrice: any;
  territoryList: any = [];
  productId: string;
  errorMsg = ERROR_MESSAGES;
  obj = Object.getOwnPropertyNames;
  fileSize: any;
  userId: any;
  productTypeList: any = [];


  constructor(private productService: ProductService,
    private alertService: AlertService, private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.userId = localStorage.getItem('userId');
    this.buildForm();
    this.productId = this.route.snapshot.params.id;
    this.getProductTypes();
    this.getTerritories();
    if (this.productId) {
      this.getProductById();
    }
  }

  /**
   * @method buildForm()
   * @desc used to build form.
   */
  buildForm(): void {
    this.productForm = this.formBuilder.group({
      productMinQty: [this.productDetails.productDetails.product_minimum_Order_Quantity,
      [Validators.required, COMMONFORMVALIDATIONRULE.withoutDecimal, Validators.min(1)]],
      productDescription: [this.productDetails.productDetails.product_Description, [Validators.required, Validators.maxLength(500)]],
      productTerritory: [this.productDetails.productTerritoryMapping, [Validators.required]],
      aliasProductName: [this.productDetails.aliasProductName, [Validators.required, COMMONFORMVALIDATIONRULE.productName]],
      productType: [this.productDetails.productType, [Validators.required]]
    });
  }

  readUrl(event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.imageFile = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
      this.fileSize = event.target.files[0].size / 1024;
    }
  }

  getProductById(): void {
    this.isLoading = true;
    this.productService.getProductById(this.productId).subscribe(
      res => {
        this.isLoading = false;
        const territoryValues = [];
        this.productDetails = res.productMaster[0];
        this.imageFile = this.productDetails.productImage.length ? this.productDetails.productImage[0].product_ImageURL + '?' + new Date() : '';
        this.productPrice = this.productDetails.productPricing.length ? this.productDetails.productPricing[0].product_Price : '';
        if (this.productDetails.productTerritoryMapping.length) {
          this.productDetails.productTerritoryMapping.forEach(ele => {
            territoryValues.push(ele.territory_code);
          });
        }
        if (territoryValues.length == this.territoryList.length || territoryValues.length == 0) {
          setTimeout(() => {
            this.allSelected.select();
            this.productForm.controls.productTerritory
              .patchValue([...this.territoryList.map(item => item.territory_code), 0]);
          }, 20);
        }
        this.productForm.patchValue({
          productMinQty: this.productDetails.productDetails ? this.productDetails.productDetails.product_minimum_Order_Quantity : '',
          productDescription: this.productDetails.productDetails ? this.productDetails.productDetails.product_Description : '',
          productTerritory: territoryValues,
          aliasProductName: this.productDetails.productDetails ? this.productDetails.productDetails.product_name_alias : '',
          productType: this.productDetails.productDetails ? this.productDetails.productDetails.productType.id : null
        });
      },
      error => {
        this.isLoading = false;
        this.alertService.error(error.error.title)
      }
    );
  }

  getProductTypes(): void {
    this.productService.getProductTypes(this.productLOB).subscribe(
      res => {
        this.productTypeList = res.productType;
      },
      error => {
        this.alertService.error(error.error.title)
      }
    );
  }

  getTerritories(): void {
    this.productService.getTerritories().subscribe(
      res => {
        this.territoryList = res.territories;
      },
      error => {
        this.alertService.error(error.error.title)
      }
    );
  }

  trackByFn(): any {
    return null;
  }

  tosslePerOne(all) {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
      return false;
    }
    if (this.productForm.controls.productTerritory.value.length == this.territoryList.length)
      this.allSelected.select();
  }

  toggleAllSelection() {
    if (this.allSelected.selected) {
      this.productForm.controls.productTerritory
        .patchValue([...this.territoryList.map(item => item.territory_code), 0]);
    } else {
      this.productForm.controls.productTerritory.patchValue([]);
    }
  }

  saveProductDetails(data) {
    
    if (this.fileSize > 50) {
      this.alertService.error(this.errorMsg.IMAGE_SIZE);
      return;
    }
    if (this.allSelected.selected) {
      this.productForm.value.productTerritory.splice(-1, 1);
    }
    this.isLoading = true;
    var payload = {
      product_Id: decodeURIComponent(this.productId),
      product_description: this.productForm.value.productDescription,
      product_minimum_Order_Quantity: this.productForm.value.productMinQty,
      product_Territories: this.productForm.value.productTerritory,
      createdBy: this.userId,
      product_name_alias: this.productForm.value.aliasProductName,
      product_ImageURL: this.imageFile || data.productImage[0].product_ImageURL,
      productTypeID: this.productForm.value.productType
    }
    this.productService.saveProduct(payload).subscribe(
      res => {
        this.isLoading = false;
        if (res.statusCode == 200) {
          this.router.navigate(['/product']);
          this.alertService.success(this.errorMsg.PRODUCT_SAVED)
        }
      },
      error => {
        this.isLoading = false;
        this.alertService.error(error.error.title)
      }
    );
  }
}